import { useLoaderData, useOutletContext } from "react-router-dom";
import Card from "../../../Components/Card";
import { EvjuSpaceEvent, deleteEvent, getEvent, updateEvent } from "../../../../connectors/EventConnector";
import { group } from "console";
import { EvjuSpaceGroup, getGroup } from "../../../../connectors/GroupConnector";
import EvjuSpaceButton from "../../../Components/FormElements/EvjuSpaceButton";
import EvjuSpaceCheckbox from "../../../Components/FormElements/EvjuSpaceCheckbox";
import EvjuSpaceDateTime from "../../../Components/FormElements/EvjuSpaceDateTime";
import EvjuSpaceInput from "../../../Components/FormElements/EvjuSpaceInput";
import EvjuSpaceUserSelector from "../../../Components/FormElements/EvjuSpaceUserSelectorNEW";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../../context/UserContext";

function EventSettings() {

    const [userContext, setUserContext] = useContext<any>(UserContext);
    const { group, event } :any = useLoaderData();

    const [userSet, setUserSet] = useState<Array<string>>([])

    const [name, setName] = useState<string>(event.name);
    const [description, setDescription] = useState<string>(event.description);
    const [starttime, setStarttime] = useState<Date>(new Date(event.starttime));
    const [started, setStarted] = useState<Boolean>(event.started);
    const [endtime, setEndtime] = useState<Date>(new Date(event.endtime));
    const [ended, setEnded] = useState<Boolean>(event.ended);
    const [host, setHost] = useState<Array<string>>(event.host);
    const [secretary, setSecratary] = useState<Array<string>>(event.secretary);
    const [proofreader, setProofreader] = useState<Array<string>>(event.proofreader);
    const [isOffline, setIsOffline] = useState<boolean>(event.location.isOffline);
    const [offlineName, setOfflineName] = useState<string>(event.location.offline?.name);
    const [offlineDescription, setOfflineDescription] = useState<string>(event.location.offline?.description);
    const [offlineStreet, setOfflineStreet] = useState<string>(event.location.offline?.street);
    const [offlineNumber, setOfflineNumber] = useState<string>(event.location.offline?.number);
    const [offlinePostalcode, setOfflinePostalcode] = useState<string>(event.location.offline?.postalcode);
    const [offlineCity, setOfflineCity] = useState<string>(event.location.offline?.city);
    const [offlineRoom, setOfflineRoom] = useState<string>(event.location.offline?.room);
    const [isOnline, setIsOnline] = useState<boolean>(event.location.isOnline);
    const [onlineName, setOnlineName] = useState<string>(event.location.online?.name);
    const [onlineDescription, setOnlineDescription] = useState<string>(event.location.online?.description);
    const [onlineLink, setOnlineLink] = useState<string>(event.location.online?.link);

    useEffect(() => {
        if(userContext.details) {
            if(!group.isAdmin && !isHost()) {
                window.location.replace("/group/" + group._id + "/event/" + event._id);
            }
        }
    }, [userContext])

    useEffect(() => {
        let newUserSet: Array<string> = []
        for(const member of group.members) {
            newUserSet.push(member.id)
        }
        setUserSet(newUserSet)
    }, [])

    function isHost(): boolean {
         return event.host.indexOf(userContext.details?._id) > -1
    }

    async function onSave() {
        const response = await updateEvent(
            {
                _id: event._id,
                name: name,
                description: description,
                starttime: starttime,
                started: started,
                endtime: endtime,
                ended: ended,
                location: {
                    isOffline: isOffline,
                    offline: {
                        name: offlineName,
                        description: offlineDescription,
                        street: offlineStreet,
                        number: offlineNumber,
                        postalcode: offlinePostalcode,
                        city: offlineCity,
                        room: offlineRoom,
                    },
                    isOnline: isOnline,
                    online: {
                        name: onlineName,
                        description: onlineDescription,
                        link: onlineLink,
                    },
                },
                group: group._id,
                host: host? host : undefined,
                secretary: secretary? secretary : undefined,
                proofreader: proofreader? proofreader : undefined,

            }
        );

        if(response._id) {
            window.location.href = '/group/' + group._id + '/event/' + event._id
        }
    }

    async function onDelete() {
        if (window.confirm('Willst du diese Veranstaltung wirklich löschen?')) {
            const response = await deleteEvent(event._id);
            if(response) {
                window.location.href = '/group/' + group._id
            }
        }
    }

    return (
        <>
            <Card heading={event? event.name : "Loading..."} headerBox="Veranstaltung bearbeiten" back={event._id && group._id ? "/group/" + group._id + "/event/" + event._id : "#"} >
                <h3>Daten der Veranstaltung bearbeiten</h3>
                <EvjuSpaceInput label="Name der Veranstaltung" value={name} onChange={setName} />
                <EvjuSpaceInput label="Beschreibung der Veranstaltung" value={description} onChange={setDescription} />
                <EvjuSpaceDateTime label="Beginn der Veranstaltung" value={starttime} onChange={setStarttime} />
                <EvjuSpaceDateTime label="Ende der Veranstaltung" value={endtime} onChange={setEndtime} />
                <EvjuSpaceUserSelector label={"Sitzungsleiter"} value={host} onChange={setHost} set={userSet} />
                <EvjuSpaceUserSelector label={"Protokoll schreiben"} value={secretary} onChange={setSecratary} set={userSet} multiselect={true} />
                <EvjuSpaceUserSelector label={"Protokoll korrekturlesen"} value={proofreader} onChange={setProofreader} set={userSet} multiselect={true} />

                <EvjuSpaceCheckbox label="Die Veranstaltung findet Offline statt." value={isOffline} onChange={setIsOffline} />
                {isOffline ? <EvjuSpaceInput label="Name des Ortes" value={offlineName} onChange={setOfflineName} /> : ""}
                {isOffline ? <EvjuSpaceInput label="Beschreibung des Ortes" value={offlineDescription} onChange={setOfflineDescription} /> : ""}
                {isOffline ? <EvjuSpaceInput label="Straße" value={offlineStreet} onChange={setOfflineStreet} /> : ""}
                {isOffline ? <EvjuSpaceInput label="Nummer" value={offlineNumber} onChange={setOfflineNumber} /> : ""}
                {isOffline ? <EvjuSpaceInput label="PLZ" value={offlinePostalcode} onChange={setOfflinePostalcode} /> : ""}
                {isOffline ? <EvjuSpaceInput label="Ort" value={offlineCity} onChange={setOfflineCity} /> : ""}
                {isOffline ? <EvjuSpaceInput label="Raum" value={offlineRoom} onChange={setOfflineRoom} /> : ""}

                <EvjuSpaceCheckbox label="Die Veranstaltung findet Online statt." value={isOnline} onChange={setIsOnline} />
                {isOnline ? <EvjuSpaceInput label="Name des Ortes" value={onlineName} onChange={setOnlineName} /> : ""}
                {isOnline ? <EvjuSpaceInput label="Beschreibung des Ortes" value={onlineDescription} onChange={setOnlineDescription} /> : ""}
                {isOnline ? <EvjuSpaceInput label="Link" value={onlineLink} onChange={setOnlineLink} /> : ""}
                <EvjuSpaceButton text="Speichern" onClick={onSave} />
                <hr />
                <h3>Einladung verschicken</h3>
                <hr />
                <h3>Protokoll verschicken</h3>
                <hr />
                <h3>Veranstaltung löschen</h3>
                <EvjuSpaceButton text="Löschen" onClick={onDelete} />
            </Card>
        </>
    );
}
export default EventSettings;

export async function eventSettingsLoader({ params } : any) {

    const group:EvjuSpaceGroup = await getGroup(params.groupid)

    const event:EvjuSpaceEvent = await getEvent(params.eventid)
    return { group, event };
}