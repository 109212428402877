import "./../style.css";
import 'remixicon/fonts/remixicon.css'

function Card(props : any) {


    return (
        <div className="content-container">
            <div className="card-heading">
                <h1>{props.heading}</h1>
            </div>
            <div className="card-container">
                <div className="card-header">
                    <div className="card-backbtn">
                    {props.back ? <a className="card-icon ri-arrow-left-line" title="Zurück" href={props.back}></a> : ""}
                    </div>
                    <div className="card-title">
                        {props.headerBox}
                    </div>
                    <div className="card-settings">
                        {props.settings ? <a className="card-icon ri-settings-4-line" href={props.settings}></a> : ""}
                    </div>
                </div>
                <div className="card-content">
                    {props.children}
                </div>
            </div>
        </div>);

}
export default Card;