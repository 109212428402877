import { useLoaderData } from "react-router-dom";
import { EvjuSpaceGroup, getGroup } from "../../../connectors/GroupConnector";
import Card from "../../Components/Card";
import GroupEventWidget from "../../Components/Widgets/GroupEventWidget";
import Widget from "../../Components/Widgets/Widget";
import WidgetArea from "../../Components/Widgets/WidgetArea";
import DescriptionWidget from "../../Components/Widgets/DescriptionWidget";
import MembersWidget from "../../Components/Widgets/MembersWidget";
import { EvjuSpaceEventSlim, getEvents } from "../../../connectors/EventConnector";
import { EvjuSpaceUser, getUser } from "../../../connectors/UserConnector";


function Group() {

    const [ group, events, members ] : any = useLoaderData();

    return (
        <Card heading={group.name} headerBox={""} settings={group.isAdmin ? ("/group/" + group._id + "/settings") : ""}>
            <WidgetArea>
                <DescriptionWidget description={ group.description } headline="Gruppenbeschreibung" />

                <GroupEventWidget group={group} events={events} />
                
                <Widget headerBox="Vergangene Termine" standardHeight={true}>
                    <p>Coming soon...</p>
                </Widget>
                <Widget headerBox="ToDos" standardHeight={true}>
                    <p>Coming soon...</p>
                </Widget>
                
                <MembersWidget group={group} members={members} />

                <Widget headerBox="Dateien" standardHeight={true}>
                    <p>Coming soon...</p>
                </Widget>
            </WidgetArea>
        </Card>
    );
}
export default Group;

export async function groupLoader({ params } : any) {
    //TODO: GROUP AUS DB LADEN
    const group:EvjuSpaceGroup = await getGroup(params.id)
    const events:Array<EvjuSpaceEventSlim> = await getEvents(group._id)

    let members: Array<EvjuSpaceUser> = []

    let promises: Array<Promise<EvjuSpaceUser>> = []
    group.members?.forEach((member) => {
        promises.push(getUser(member.id))
    })

    await Promise.all(promises).then((m) => {
        members = m
    })

    //const events:EvjuSpaceEventSlim[] = await getEvents(params.id);
    return [ group, events, members ];
}