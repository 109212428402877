import {Editor, EditorState, RichUtils, convertFromRaw, convertToRaw} from 'draft-js';
import 'draft-js/dist/Draft.css';
import { SetStateAction, useEffect, useState } from 'react';

export interface editorProps {
    rawState: string,
    onChangeExportRawState?: any,
    label?: string,
}

function EvjuSpaceEditor(this: any, props:editorProps) {

    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());

    useEffect(() => {
        if(props.rawState && props.rawState != "") {
            setEditorState(EditorState.createWithContent(convertFromRaw(JSON.parse(props.rawState))))
        }
    }, [])

    useEffect(() => {
        if(props.onChangeExportRawState){
            props.onChangeExportRawState(convertToRaw(editorState.getCurrentContent()))
            // props.getRawState(convertToHTML(editorState))
        }
    }, [editorState])

    function handleKeyCommand(this: any, command: string, editorState: EditorState) {
        const newState = RichUtils.handleKeyCommand(editorState, command);
    
        if (newState) {
            this.onChange(newState);
            return 'handled';
        }
    
        return 'not-handled';
    }

    function onBoldClick(this: any) {
        setEditorState(RichUtils.toggleInlineStyle(editorState, 'BOLD'));
    }
    function onItalicClick(this: any) {
        setEditorState(RichUtils.toggleInlineStyle(editorState, 'ITALIC'));
    }
    function onUnderlineClick(this: any) {
        setEditorState(RichUtils.toggleInlineStyle(editorState, 'UNDERLINE'));
    }

    return ( <>
        {props.label ? <label className="evjuspaceeditor-label">{props.label}</label> : ""}
        <div className='evjuspaceeditor'>
            {/* <a className="evjuspaceeditor-button ri-font-size" title="Schriftgröße" onClick={() => {console.log("ToDo")}}></a> */}
            <a className="evjuspaceeditor-button ri-bold" title="Fett" onClick={onBoldClick.bind(this)}></a>
            <a className="evjuspaceeditor-button ri-italic" title="Kursiv" onClick={onItalicClick.bind(this)}></a>
            <a className="evjuspaceeditor-button ri-underline" title="Unterstrichen" onClick={onUnderlineClick.bind(this)}></a>
            {/* <a className="evjuspaceeditor-button ri-list-check" title="Liste" onClick={() => {console.log("ToDo")}}></a>
            <a className="evjuspaceeditor-button ri-list-ordered" title="Liste" onClick={() => {console.log("ToDo")}}></a>
            <a className="evjuspaceeditor-button ri-table-line" title="Tabelle" onClick={() => {console.log("ToDo")}}></a>
            <a className="evjuspaceeditor-button ri-file-image-line" title="Bild einfügen" onClick={() => {console.log("ToDo")}}></a>
            <a className="evjuspaceeditor-button ri-link" title="Link einfügen" onClick={() => {console.log("ToDo")}}></a> */}
            <hr />
            <Editor editorState={editorState} handleKeyCommand={handleKeyCommand} onChange={setEditorState} />
        </div>
    </>);
}

export default EvjuSpaceEditor;
