import { useLoaderData, useOutletContext } from "react-router-dom";
import Card from "../Components/Card";
import { useEffect, useState } from "react";
import EvjuSpaceInput from "../Components/FormElements/EvjuSpaceInput";
import EvjuSpaceButton from "../Components/FormElements/EvjuSpaceButton";
import IndexCard from "../Components/IndexCard";
import EvjuSpaceDateTime from "../Components/FormElements/EvjuSpaceDateTime";
import EvjuSpaceOverlay from "../Components/Overlay";
import EvjuSpaceEditor from "../Components/FormElements/EvjuSpaceEditor";
import { Certification, EvjuSpaceUser, addCertificationToUser, archiveCertification, getUserDetails, getUsersCertifications, updateUser } from "../../connectors/UserConnector";
import EvjuSpaceCheckbox from "../Components/FormElements/EvjuSpaceCheckbox";
import { dateToString } from "../../Helper/DateFunctions";

function Profile() {
    
    const [ profile, loadedCertifications ]: any = useLoaderData();

    const [isFAQOverlayOpen, setIsFAQOverlayOpen] = useState<boolean>(false)
    const [isAddTrainingOverlayOpen, setIsAddTrainingOverlayOpen] = useState<boolean>(false)
    const [certifications, setCertifications] = useState<Array<Certification>>(loadedCertifications)

    const [firstName, setFirstName] = useState<string>(profile.firstName? profile.firstName : "")
    const [lastName, setLastName] = useState<string>(profile.lastName? profile.lastName : "")
    const [username, setUsername] = useState<string>(profile.username? profile.username : "")
    const [street, setStreet] = useState<string>(profile.street? profile.street : "")
    const [houseNumber, setHouseNumber] = useState<string>(profile.houseNumber? profile.houseNumber : "")
    const [zipCode, setZipCode] = useState<string>(profile.zipCode? profile.zipCode : "")
    const [city, setCity] = useState<string>(profile.city? profile.city : "")
    const [smartphone, setSmartphone] = useState<string>(profile.smartphone? profile.smartphone : "")
    const [dateOfBirth, setDateOfBirth] = useState<Date>(profile.dateOfBirth? new Date(profile.dateOfBirth) : new Date())
    const [driversLicense, setDriversLicense] = useState<string>(profile.driversLicense? profile.driversLicense : "")

    const [emergencyContact, setEmergencyContact] = useState<string>(profile.emergencyContact? profile.emergencyContact : "")
    const [eatingHabits, setEatingHabits] = useState<string>(profile.eatingHabits? profile.eatingHabits : "")
    const [tshirtSize, setTshirtSize] = useState<string>(profile.tshirtSize? profile.tshirtSize : "")
    const [specialties, setSpecialties] = useState<string>(profile.specialties? profile.specialties : "")

    const [addTrainingPresetSelection, setAddTrainingPresetSelection] = useState<boolean>(true)
    const [showArchivedTrainings, setShowArchivedTrainings] = useState<boolean>(false)
    const [trainingPreset, setTrainingPreset] = useState<boolean>(false)
    const [newTrainingTitle, setNewTrainingTitle] = useState<string>("")
    const [newTrainingIssueDate, setNewTrainingIssueDate] = useState<Date>(new Date())
    const [newTrainingExpires, setNewTrainingExpires] = useState<boolean>(false)
    const [newTrainingExpirationDate, setNewTrainingExpirationDate] = useState<Date|undefined>(undefined)

    useEffect(() => {
        profile.firstName = firstName
        profile.lastName = lastName
        profile.street = street
        profile.houseNumber = houseNumber
        profile.zipCode = zipCode
        profile.city = city
        profile.smartphone = smartphone
        profile.dateOfBirth = dateOfBirth
        profile.driversLicense = driversLicense

        profile.emergencyContact = emergencyContact
        profile.eatingHabits = eatingHabits
        profile.tshirtSize = tshirtSize
        profile.specialties = specialties
        updateUser(profile)
    }, [
        firstName,
        lastName,
        street,
        houseNumber,
        zipCode,
        city,
        smartphone,
        dateOfBirth,
        driversLicense,
        emergencyContact,
        eatingHabits,
        tshirtSize,
        specialties,
    ])

    function addTraining() {
        let certification:Certification = {
            userid: profile._id,
            title: newTrainingTitle,
            issueDate: newTrainingIssueDate,
            expirationDate: newTrainingExpirationDate,
        }
        addCertificationToUser(certification)
        setCertifications([
            ...certifications,
            certification
        ])
        setIsAddTrainingOverlayOpen(false)
    }
    
    function addTrainingPreset(training: string) {
        setTrainingPreset(true)
        switch(training) {
            case 'juleica':
                setNewTrainingTitle('JuLeiCa')
                setNewTrainingExpires(true)
                break
            case 'rettungsschwimmer':
                setNewTrainingTitle('Rettungsschwimmer')
                setNewTrainingExpires(true)
                break
            default:
                
        }
        setAddTrainingPresetSelection(false)
    }

    function resetNewTraining() {
        setAddTrainingPresetSelection(true)
        setNewTrainingTitle("")
        setNewTrainingIssueDate(new Date())
        setNewTrainingExpires(false)
        setNewTrainingExpirationDate(undefined)
        setTrainingPreset(false)
    }

    function archiveTraining(trainingsId: string) {
        archiveCertification(trainingsId)
        let newCertifications:Array<Certification> = []
        certifications.map((certification) => {
            if(certification._id == trainingsId) {
                certification.archived = true
            }
            newCertifications.push(certification)
        })
        setCertifications(newCertifications)
    }

    return (
        <>
            <Card heading="Einstellungen" headerBox="Profil">
                <p><a onClick={() => {setIsFAQOverlayOpen(true)}}>Warum muss ich hier so wahnsinnig viele Angaben machen?</a></p>
                <IndexCard headerBox="Persönliche Daten">
                    <EvjuSpaceInput label="Vorname" value={firstName} onChange={setFirstName} />
                    <EvjuSpaceInput label="Nachname" value={lastName} onChange={setLastName} />
                    <EvjuSpaceInput label="Email (kann nicht bearbeitet werden)" value={username} onChange={setUsername} disabled={true} />
                    <EvjuSpaceInput label="Straße" value={street} onChange={setStreet} />
                    <EvjuSpaceInput label="Hausnummer" value={houseNumber} onChange={setHouseNumber} />
                    <EvjuSpaceInput label="PLZ" value={zipCode} onChange={setZipCode} />
                    <EvjuSpaceInput label="Ort" value={city} onChange={setCity} />
                    <EvjuSpaceInput label="Smartphone" value={smartphone} onChange={setSmartphone} />
                    <EvjuSpaceDateTime label="Geburtsdatum" value={dateOfBirth} onChange={setDateOfBirth} dateOnly={true} />
                    <EvjuSpaceInput label="Führerscheinklasse" value={driversLicense} onChange={setDriversLicense} />
                </IndexCard>

                <IndexCard headerBox="Daten für Freizeiten">
                    <EvjuSpaceInput label="Notfallkontakt (Name & Telefonnummer)" value={emergencyContact} onChange={setEmergencyContact} />
                    <EvjuSpaceInput label="besondere Essgewohnheiten (vegetarisch/vegan? Unverträglichkeiten?)" value={eatingHabits} onChange={setEatingHabits} />
                    <EvjuSpaceInput label="T-Shirt-Größe" value={tshirtSize} onChange={setTshirtSize} />
                    <EvjuSpaceEditor label ="Sonstige Infos (Allergien, Medikamente, ..." rawState={specialties} onChangeExportRawState={(state: any) => {setSpecialties(JSON.stringify(state))}}></EvjuSpaceEditor>
                </IndexCard>
                
                <IndexCard headerBox="Fortbildungen">
                    <div className="profile-trainings">
                        {certifications.length > 0 ? certifications.map((certification: Certification, i: number) => {
                            return <>
                                {certification.archived? <></> :
                                    <div className="certification-box">
                                        <div className="certification-control">
                                            <i className="ri-archive-fill" title="archivieren" onClick={() => {(certification._id? archiveTraining(certification._id) : console.log("No id"))}}></i>
                                        </div>
                                        <div className="certification-data">
                                            <h3>{certification.title}</h3>
                                            <div className="certification-detail">
                                                Fortbildungsdatum: {dateToString(certification.issueDate)}
                                            </div>
                                            <div className="certification-detail">
                                                Ablaufdatum: {(certification.expirationDate? dateToString(certification.expirationDate) : "-")}
                                            </div>
                                        </div>
                                    </div>
                                }
                            </>
                        }) 
                            : "Es wurden bisher keine Fortbildungen hinterlegt."
                        }
                    </div>
                    {showArchivedTrainings?
                    <div className="profile-trainings">
                        <h3 onClick={() => {setShowArchivedTrainings(false)}}>Archivierte Fortbildungen</h3>
                        {certifications.length > 0 ? certifications.map((certification: Certification, i: number) => {
                            return <>
                                {!certification.archived? <></> :
                                    <div className="certification-box">
                                        <div className="certification-data">
                                            <h3>{certification.title}</h3>
                                            <div className="certification-detail">
                                                Fortbildungsdatum: {dateToString(certification.issueDate)}
                                            </div>
                                            <div className="certification-detail">
                                                Ablaufdatum: {(certification.expirationDate? dateToString(certification.expirationDate) : "-")}
                                            </div>
                                        </div>
                                    </div>
                                }
                            </>
                        }) 
                            : "Es wurden bisher keine Fortbildungen archiviert."
                        }
                    </div> : <a className="profile-training-link" onClick={() => {setShowArchivedTrainings(true)}}>Archivierte Fortbildungen anzeigen</a>}
                    <a className="profile-training-link" onClick={() => {setIsAddTrainingOverlayOpen(true)}}>Neue Fortbildung hinzufügen</a>
                </IndexCard>

                <IndexCard headerBox="Eintragungen durch die Leitung">
                    <p>ToDo</p>
                    <p>- Führungszeugnis vorgelegt</p>
                    <p>- Wenn Minderjährig: Darf sich in Dreiergruppe frei bewegen</p>
                </IndexCard>

                <IndexCard headerBox="Rechtliches">
                    <p>ToDo</p>
                    {/* <p>Fotozustimmung</p>
                    <p>EvJP Datenschutz</p>
                    <p>Anzeige welchen evju.space Dokumenten zugestimmt wurde</p> */}
                </IndexCard>

                <IndexCard headerBox="Passwort ändern">
                    <p>Diese Funktion gibt es leider noch nicht. Du kannst aber beim Login auf "Passwort vergessen" klicken und darüber dein Passwort ändern.</p>
                </IndexCard>

                <IndexCard headerBox="Account löschen">
                    <p>Diese Funktion gibt es leider noch nicht. Wenn du deinen Account löschen möchtest, melde dich bitte bei Lasse (015778228166).</p>
                </IndexCard>
            </Card>

            <EvjuSpaceOverlay isOpen={isFAQOverlayOpen} onClose={() => {setIsFAQOverlayOpen(false)}} >
                <h2>Warum muss ich hier so wahnsinnig viele Angaben machen?</h2>
                <p>Musst du eigentlich gar nicht. Das ist alles freiwillig. ABER:</p>
                <p>Die Daten, die hier eingetragen werden, werden in Zukunft genutzt um automatisch Mitarbeitendenlisten für unsere Freizeiten zu erstellen.
                    Das heißt: Wenn du dein Profil vollständig ausfüllst, dann musst du in Zukunft keine nervigen Anmeldeformulare mehr ausfüllen und so weiter.
                    Die Leitung kann dann zum Beispiel auch die richtige Menge vegetarisches Essen besorgen oder deine Daten auf den Zuschusslisten schon vorausfüllen, ohne jedes Mal nachzufragen.
                    Es geht also nur darum in Zukunft ein bisschen Arbeit zu sparen.</p>
                <p>Wenn du einige Daten nicht angeben möchtest ist das auch total in Ordnung. Dann musst du nur daran denken die benötigten Daten bei zukünftigen Veranstaltungen der Leitung direkt mitzuteilen.</p>

                <h2>Welche Daten werden wofür genutzt?</h2>
                <h3>Persönliche Daten</h3>
                <p>Diese Daten werden verwendet um automatisch Mitarbeitendenlisten zu generieren. Die Daten, die du bei der Registrierung bei evju.space schon angeben musstest sind für den Betrieb dieser Plattform nötig.</p>
                <h3>Daten für Freizeiten</h3>
                <p>Diese Daten werden verwendet um automatisch Mitarbeitendenlisten zu generieren und der Leitung die Organisation von Veranstaltungen zu erleichtern.</p>
                <h3>Fortbildungen</h3>
                <p>Diese Daten werden genutzt um der Leitung die Organisation von Veranstaltungen und das Beantragen von Zuschüssen zu erleichtern.
                    Wenn du zum Beispiel mal ein Ehrenamtszeugnis für eine Bewerbung brauchst, kann die Leitung diese Daten dort aufnehmen.</p>
                <h3>Eintragungen durch die Leitung</h3>
                <p>Hier kann die Leitung Infos hinterlegen. (Z.B: zu Führungszeugnissen o.Ä.)</p>
                <p>Du kannst <b>alle</b> Infos sehen, die über dich hinterlegt wurden!</p>
            </EvjuSpaceOverlay>
            <EvjuSpaceOverlay isOpen={isAddTrainingOverlayOpen} onClose={() => {
                    setIsAddTrainingOverlayOpen(false)
                    resetNewTraining()
                }}>

                
                {addTrainingPresetSelection?
                <>
                <div className="profile-add-training-preset" onClick={() => {addTrainingPreset('juleica')}}>JuLeiCa</div>
                <div className="profile-add-training-preset" onClick={() => {addTrainingPreset('rettungsschwimmer')}}>Rettungsschwimmer</div>
                <div className="profile-add-training-preset" onClick={() => {addTrainingPreset('')}}>Sonstiges</div>
                </> : <>
                    <h3>Neue Fortbildung anlegen</h3>
                    <EvjuSpaceInput label="Bezeichnung" value={newTrainingTitle} onChange={setNewTrainingTitle} disabled={trainingPreset} />
                    <EvjuSpaceDateTime label="Fortbildungsdatum" value={newTrainingIssueDate} onChange={setNewTrainingIssueDate} dateOnly={true} />
                    {newTrainingExpires?
                        <EvjuSpaceDateTime label="Ablaufdatum" value={newTrainingExpirationDate} onChange={setNewTrainingExpirationDate} dateOnly={true} /> :
                        <EvjuSpaceCheckbox label="Diese Fortbildung läuft ab" value={newTrainingExpires} onChange={setNewTrainingExpires} />
                    }
                    <EvjuSpaceButton text="Fortbildung anlegen" onClick={addTraining} />
                </>}
            </EvjuSpaceOverlay>
        </>
    );
}
export default Profile;

export async function ProfileLoader({ params } : any) {
    const profile:EvjuSpaceUser = await getUserDetails();
    const certfifications:Array<Certification> = await getUsersCertifications(profile._id);
    return [ profile, certfifications ];
}