import ApplyToServer from "../Components/ApplyToServer";
import Card from "../Components/Card";

function ApplyToServerSettings() {

    return (
        <>
            <Card heading="Einstellungen" headerBox="Werde Mitglied bei anderen Servern!">
                <ApplyToServer />
            </Card>
        </>
    );
}
export default ApplyToServerSettings;