import "./../../style.css";
import 'remixicon/fonts/remixicon.css'
import { Children } from "react";

function WidgetArea(props : any) {
    const numberOfChildren: number = Children.count(props.children)

    let columns:string = "widget-area-3"
    if(numberOfChildren === 2) {
        columns = "widget-area-2"
    } else if(numberOfChildren === 1) {
        columns = "widget-area-1"
    }

    if(props.columns && props.columns == 2) {
        columns = "widget-area-2"
    } else if(props.columns && props.columns == 1) {
        columns = "widget-area-1"
    }

    return (
        <div className={"widget-area " + columns}>
            {props.children}
        </div>
    );

}
export default WidgetArea;