export function dateToString(d:Date):string {
    const date =new Date(d)

    const day = makeDoubleDigits("" + date.getDate())
    const month= makeDoubleDigits("" + (date.getMonth() +1))
    const year = makeDoubleDigits("" + date.getFullYear())
    const hours = makeDoubleDigits("" + date.getHours())
    const minutes = makeDoubleDigits("" + date.getMinutes())
    return day + "." + month + "." + year + " " + hours + ":" + minutes;
}

export function dateToFilenameFormat(d:Date):string {
    const date =new Date(d)

    const day = makeDoubleDigits("" + date.getDate())
    const month= makeDoubleDigits("" + (date.getMonth() +1))
    const year = makeDoubleDigits("" + date.getFullYear())
    return year + "_" + month + "_" + day;
}

function makeDoubleDigits(s:string):string {
    if(s.length > 1) {
        return s
    } else {
        return "0" + s
    }
}