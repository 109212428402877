import { HTMLInputTypeAttribute } from "react";
import "./../../style.css";
import 'remixicon/fonts/remixicon.css'

interface EvjuSpaceInputParameter {
    type?: HTMLInputTypeAttribute,
    label?: string,
    value?: any,
    class?: string,
    disabled?: boolean,
    onChange?: any,
    onSave?:any,
    onFocus?: any,
}

function EvjuSpaceInput(props : EvjuSpaceInputParameter) {

    function change(e:any) {
        if(props.onChange) {
            props.onChange(e.target.value)
        }
    }

    function focus(e:any) {
        if(props.onFocus) {
            props.onFocus(e)
        }
    }

    let cssClass:String = ""
    if(props.class) {
        cssClass = props.class
    }

    let type:HTMLInputTypeAttribute = "text"
    if(props.type) {
        type = props.type
    }

    return (
        <div className="evjuspaceinput-container">
            <div className="evjuspaceinput-label">
                { props.label ? props.label : "" }
            </div>
                <input
                    className={"evjuspaceinput evjuspaceinput-input " + cssClass}
                    type={type}
                    value={ props.value ? props.value : ""}
                    disabled={ props.disabled && props.disabled == true ? true : false}
                    onChange={ change }
                    onFocus={focus}
                />
                {props.onSave ? (
                    <a className="evjuspaceinput-input-onsave ri-save-3-line" onClick={props.onSave}></a>
                ): ""}
        </div>);

}
export default EvjuSpaceInput;