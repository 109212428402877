import { useEffect, useState } from "react";
import EvjuSpaceInput from "../../../../Components/FormElements/EvjuSpaceInput";

function ProtocolVotingResultEditor(props : any) {


    const [votingResult, setVotingResult] = useState<any>(JSON.parse(props.votingResult))

    useEffect(() => {
        props.onChange(JSON.stringify(votingResult))
    }, [JSON.stringify(votingResult)])

    function onChange(index: number, votes: string) {
        if(!isNaN(+votes)) {
            let newResult = votingResult.result.map((option: { option: string; votes: string; }, i: number) => {
                if(i == index) {
                    option.votes = votes
                    return option
                } else {
                    return option
                }
            })

            setVotingResult({"result": newResult});
        }
    }

    return (
        <>
            {votingResult.result && votingResult.result.map((option: any, i:number) => {
                return (
                        <EvjuSpaceInput key={"VotingResultEditor"+i} label={option.option} value={option.votes} onChange={(s: string) => {onChange(i, s)}} />
                );
            })}
        </>
    );
}

export default ProtocolVotingResultEditor;