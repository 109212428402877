import { useLoaderData } from "react-router-dom";
import Card from "../../Components/Card";
import { addMemberToGroup, getGroup, updateGroup } from "../../../connectors/GroupConnector";
import EvjuSpaceInput from "../../Components/FormElements/EvjuSpaceInput";
import { useEffect, useState } from "react";
import EvjuSpaceEditor from "../../Components/FormElements/EvjuSpaceEditor";
import EvjuSpaceMemberList from "../../Components/MemberList";
import { getServerFromGroup } from "../../../connectors/ServerConnector";
import { EvjuSpaceUser, getUser } from "../../../connectors/UserConnector";
import EvjuSpaceOverlay from "../../Components/Overlay";
import { dateToString } from "../../../Helper/DateFunctions";
import { mainModule } from "process";
import draftToHtml from "draftjs-to-html";

function GroupMembers() {

    const [ group, members ] : any = useLoaderData();

    const [isColumnsOverlayOpen, setIsColumnsOverlayOpen] = useState<boolean>(false)
    const [columns, setColumns] = useState<Array<{name: string, active: boolean}>>([
        {name: "Vorname", active: true},
        {name: "Nachname", active: true},
        {name: "E-Mail", active: true},
        {name: "Straße", active: false},
        {name: "Hausnummer", active: false},
        {name: "PLZ", active: false},
        {name: "Ort", active: false},
        {name: "Geburtstag", active: true},
        {name: "Smartphone", active: true},
        {name: "Führerscheinklasse", active: false},
        {name: "Notfallkontakt", active: false},
        {name: "Essgewohnheiten", active: false},
        {name: "T-Shirt-Größe", active: false},
        {name: "Sonstige Infos", active: false},
    ])


    useEffect(() => {
        if(!group.isAdmin) {
            window.location.replace("/group/" + group._id);
        }
    }, [])

    function toggleSelection(columnName: string) {
        let newSelection = columns.map((column) => {
            if(column.name == columnName) {
                column.active = !column.active
            }
            return {name: column.name, active: column.active}
        })
        setColumns(newSelection)
    }

    function getMemberValue(columnName: string, member:EvjuSpaceUser):string {
        switch(columnName) {
            case "Vorname":
                return member.firstName? member.firstName : "-"
            case "Nachname":
                return member.lastName? member.lastName : "-"
            case "E-Mail":
                return member.username? member.username : "-"
            case "Straße":
                return member.street? member.street : "-"
            case "Hausnummer":
                return member.houseNumber? member.houseNumber : "-"
            case "PLZ":
                return member.zipCode? member.zipCode : "-"
            case "Ort":
                return member.city? member.city : "-"
            case "Geburtstag":
                return member.dateOfBirth? dateToString(member.dateOfBirth) : "-"
            case "Smartphone":
                return member.smartphone? member.smartphone : "-"
            case "Führerscheinklasse":
                return member.driversLicense? member.driversLicense : "-"
            case "Notfallkontakt":
                return member.emergencyContact? member.emergencyContact : "-"
            case "Essgewohnheiten":
                return member.eatingHabits? member.eatingHabits : "-"
            case "T-Shirt-Größe":
                return member.tshirtSize? member.tshirtSize : "-"
            case "Sonstige Infos":
                return member.specialties? draftToHtml(JSON.parse(member.specialties)) : "-"
        }
        return ""
    }

    return (
        <>
            <Card heading={group? group.name : "Loading..."} headerBox="Mitgliederdatenbank" back={group._id ? "/group/" + group._id : "#"} >
                <p>
                    <a onClick={() => {setIsColumnsOverlayOpen(true)}}>Spalten bearbeiten</a>
                </p>
                <table>
                    <thead>
                        <tr>
                            {columns.map((column, i) => {
                                return column.active? <th key={"th-"+i}>{column.name}</th> : ""
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {members.map((member: EvjuSpaceUser, i: number) => {
                            return <tr key={"member-"+i}>
                                {columns.map((column, j) => {
                                    if(column.name == "Sonstige Infos") {
                                        return column.active? <td key={"td-"+i+"-"+j} dangerouslySetInnerHTML={{__html: getMemberValue(column.name, member)}}></td> : ""
                                    } else {
                                        return column.active? <td key={"td-"+i+"-"+j}>{getMemberValue(column.name, member)}</td> : ""
                                    }
                                    
                                })}
                            </tr>
                        })}
                    </tbody>
                </table>
            </Card>
            <EvjuSpaceOverlay isOpen={isColumnsOverlayOpen} onClose={() => {setIsColumnsOverlayOpen(false)}}>
                <h2>Spalten auswählen</h2>
                <table>
                    <thead>
                        <tr>
                            <th>Spalte</th>
                            <th>Auswahl</th>
                        </tr>
                    </thead>
                    <tbody>
                        {columns?.map((column, i) => {
                            return (
                                <tr key={"column-"+i}>
                                    <td>{column.name}</td>
                                    <td>{column.active ?
                                        (<a className="toggle-button ri-toggle-fill" title="Anwesenheit einschalten" onClick={() => {toggleSelection(column.name)}}></a>) :
                                        (<a className="toggle-button ri-toggle-line" title="Anwesenheit ausschalten" onClick={() => {toggleSelection(column.name)}}></a>)}
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </EvjuSpaceOverlay>
        </>
    );
}
export default GroupMembers;

export async function groupMemberLoader({ params } : any) {

    const group = await getGroup(params.id)
    // const server = await getServerFromGroup(params.id)
    let members: Array<EvjuSpaceUser> = []

    let promises: Array<Promise<EvjuSpaceUser>> = []
    group.members?.forEach((member) => {
        promises.push(getUser(member.id))
    })

    await Promise.all(promises).then((m) => {
        members = m
    })

    return [ group, members ]
}