export interface EvJuSpaceMailSetting {
    _id?: string,
    name: string,
    value: string,
}

export async function setEmailSetting(name: string, value: string) {
    const response = await fetch(process.env.REACT_APP_EMAIL_SERVICE + "email/setsetting/" + name, {
        method: "POST",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
            value: value,
        }),
    })

    if (response.ok) {
        const responsejson = await response.json()
        return responsejson;
    } else {
        console.error(response)
    }
}

export async function getEmailSettings():Promise<EvJuSpaceMailSetting[]> {
    const response = await fetch(process.env.REACT_APP_EMAIL_SERVICE + "email/getsettings/", {
        method: "GET",
        credentials: "include",
        // Pass authentication token as bearer token in header
        headers: {
            "Content-Type": "application/json",
        },
    })
    const data = await response.json()
    return data
}

export async function sendWelcomeMail(id: string) {
    const response = await fetch(process.env.REACT_APP_EMAIL_SERVICE + "email/welcomeMail/" + id, {
        method: "GET",
        credentials: "include",
        headers: {
            "Content-Type": "application/json"
        },
    })

    if (response.ok) {
        const responsejson = await response.json()
        return responsejson;
    } else {
        console.error(response)
    }
}

export async function resendEmailConfirmation() {
    const response = await fetch(process.env.REACT_APP_EMAIL_SERVICE + "email/resendEmailConfirmation/", {
        method: "GET",
        credentials: "include",
        headers: {
            "Content-Type": "application/json"
        },
    })

    if (response.ok) {
        const responsejson = await response.json()
        return responsejson;
    } else {
        console.error(response)
    }
}