import { useLoaderData } from "react-router-dom";
import Card from "../../../Components/Card";
import { EvjuSpaceAgendaItem, EvjuSpaceEvent, getEvent, updateAgenda } from "../../../../connectors/EventConnector";
import { useContext, useEffect, useState } from "react";
import EvjuSpaceInput from "../../../Components/FormElements/EvjuSpaceInput";
import EvjuSpaceButton from "../../../Components/FormElements/EvjuSpaceButton";
import { tmpdir } from "os";
import { arrayMoveImmutable } from "array-move";
import { getGroup } from "../../../../connectors/GroupConnector";
import { UserContext } from "../../../../context/UserContext";

function CreateAgenda() {

    const [userContext, setUserContext] = useContext<any>(UserContext);
    let {event, group} : any = useLoaderData();

    const [agenda, setAgenda] = useState<EvjuSpaceAgendaItem[]>(event.agenda)
    const [newAgendaItem, setNewAgendaItem] = useState<string>("")
    const [description, setDescription] = useState<string>("")

    const [addSubAgendaItem, setAddSubAgendaItem] = useState<number|null>(null)
    const [newSubAgendaItem, setNewSubAgendaItem] = useState<string>("")
    const [newSubDescription, setNewSubDescription] = useState<string>("")

    
    useEffect(() => {
        if(userContext.details) {
            if(!group.isAdmin && !isHost()) {
                window.location.replace("/group/" + group._id + "/event/" + event._id);
            }
        }
    }, [userContext])

    function isHost(): boolean {
         return event.host.indexOf(userContext.details?._id) > -1
    }

    useEffect(() => {
        if(event._id) {
            updateAgenda(event._id, agenda)
            console.log("!")
        } else {
        }
    // }, [JSON.stringify(agenda)])
    }, [`${agenda}`])

    async function updateEvent() {
        event = await getEvent(event._id)
    }

    function btnNewAgendaItem() {
        if(agenda) {
            setAgenda(agenda => [...agenda, {name: newAgendaItem, description: description}])
        } else {
            setAgenda([{name: newAgendaItem, description: description}])
        }

        setNewAgendaItem("")
        setDescription("")
    }

    function btnNewSubAgendaItem() {
        if(agenda && addSubAgendaItem != null) {
            let tmpAgenda = agenda
            if(tmpAgenda[addSubAgendaItem].subAgenda) {
                tmpAgenda[addSubAgendaItem].subAgenda = [...tmpAgenda[addSubAgendaItem].subAgenda!, {name: newSubAgendaItem, description: newSubDescription}]
                setAgenda(tmpAgenda)
                updateAgenda(event._id, tmpAgenda)
                setNewSubAgendaItem("")
                setNewSubDescription("")
                setAddSubAgendaItem(null)
            }
        }
    }

    async function moveAgendaItemUp(i:number) {
        await updateEvent()
        if(i > 0) {
            let tmpAgenda = arrayMoveImmutable(agenda, i, i-1)
            setAgenda(tmpAgenda)
            updateAgenda(event._id, tmpAgenda)
        }
    }

    async function moveAgendaItemDown(i:number) {
        await updateEvent()
        if(i < agenda.length) {
            let tmpAgenda = arrayMoveImmutable(agenda, i, i+1)
            setAgenda(tmpAgenda)
            updateAgenda(event._id, tmpAgenda)
        }
    }

    async function deleteAgendaItem(i:number) {
        await updateEvent()
        let tmpAgenda = agenda
        tmpAgenda.splice(i, 1)
        setAgenda(tmpAgenda)
        updateAgenda(event._id, tmpAgenda)
    }

    async function moveSubAgendaItemUp(i:number, j:number) {
        await updateEvent()
        if(j > 0) {
            let tmpAgenda = agenda
            tmpAgenda[i].subAgenda = arrayMoveImmutable(agenda[i].subAgenda!, j, j-1)
            setAgenda(tmpAgenda)
            updateAgenda(event._id, tmpAgenda)
        }
    }

    async function moveSubAgendaItemDown(i:number, j:number) {
        await updateEvent()
        if(j < agenda[i].subAgenda!.length) {
            let tmpAgenda = agenda
            tmpAgenda[i].subAgenda = arrayMoveImmutable(agenda[i].subAgenda!, j, j+1)
            setAgenda(tmpAgenda)
            updateAgenda(event._id, tmpAgenda)
        }
    }
    
    async function deleteSubAgendaItem(i:number, j:number) {
        await updateEvent()
        let tmpAgenda = agenda
        if(tmpAgenda[i].subAgenda) {
            tmpAgenda[i].subAgenda!.splice(j, 1)
        }
        setAgenda(tmpAgenda)
        updateAgenda(event._id, tmpAgenda)
    }

    return (
        <Card heading={event ? event.name : "Loading..."} headerBox="Tagesordnung" back={"/group/" + group._id + "/event/" + event._id + "/" }>
            {event.started ? <div className="warning">
                    Die Veranstaltung wurde bereits gestartet. Bitte stell sicher, dass niemand das Protokoll bearbeitet, während du die Tagesordnung veränderst, sonst kann es zu <b>Datenverlust</b> kommen!<br />
                    Solltest du Tagesordnungspunkte löschen, für die bereits etwas protokolliert wurde, werden die entsprechenden Protokolleinträge <b>unwiderruflich</b> mitgelöscht!
                </div> : ""}
            {agenda ? agenda.map((agendaItem:EvjuSpaceAgendaItem, i:number) => {
                return (
                    <div className="agendaitem-container">
                        <div className="agendaitem" key={"agendaitem-" + agendaItem.name}>
                            <div className="agendaitem-text">
                                <span className="agendaitem-number">{ (i+1) + "." }</span>
                                {agendaItem.name}
                            </div>
                            <div className="agendaitem-tools">
                                <a className="list-icon ri-add-line" href="#" title="Unterpunkt hinzufügen" onClick={() => {setAddSubAgendaItem(i)}}></a>
                                <a className="list-icon ri-arrow-up-line" href="#" title="Hoch bewegen" onClick={() => {moveAgendaItemUp(i)}}></a>
                                <a className="list-icon ri-arrow-down-line" href="#" title="Runter bewegen" onClick={() => {moveAgendaItemDown(i)}}></a>
                                <a className="list-icon ri-delete-bin-line" href="#" title="Löschen" onClick={() => {deleteAgendaItem(i)}}></a>
                            </div>
                        </div>
                        {addSubAgendaItem == i ? 
                            <div>
                                <EvjuSpaceInput label="Neuer Unterpunkt:" value={newSubAgendaItem} onChange={setNewSubAgendaItem} />
                                <EvjuSpaceInput label="Beschreibung:" value={newSubDescription} onChange={setNewSubDescription} />
                                <EvjuSpaceButton text="Hinzufügen" onClick={btnNewSubAgendaItem} />
                            </div>
                        : ""}
                        {agendaItem.subAgenda ? agendaItem.subAgenda.map((subAgendaItem, j) => {
                            return (
                                <div className="subagendaitem">
                                    <div className="agendaitem-text">
                                        <span className="subagendaitem-number">{ (i+1) + "." + (j+1) + "." }</span>
                                        {subAgendaItem.name}
                                    </div>
                                    <div className="agendaitem-tools">
                                        <a className="list-icon ri-arrow-up-line" href="#" title="Hoch bewegen" onClick={() => {moveSubAgendaItemUp(i, j)}}></a>
                                        <a className="list-icon ri-arrow-down-line" href="#" title="Runter bewegen" onClick={() => {moveSubAgendaItemDown(i, j)}}></a>
                                        <a className="list-icon ri-delete-bin-line" href="#" title="Löschen" onClick={() => {deleteSubAgendaItem(i, j)}}></a>
                                    </div>
                                </div>
                            );
                        }) : ""}
                    </div>
                );
            }) : "Bisher ist keine Tagesordnung vorhanden."}
            <EvjuSpaceInput label="Neuer Tagesordnungspunkt:" value={newAgendaItem} onChange={setNewAgendaItem} />
            <EvjuSpaceInput label="Beschreibung:" value={description} onChange={setDescription} />
            <EvjuSpaceButton text="Hinzufügen" onClick={btnNewAgendaItem} />
        </Card>
    );
}
export default CreateAgenda;

export async function agendaLoader({ params } : any) {

    const event = await getEvent(params.eventid)
    const group = await getGroup(params.groupid)

    return { event, group};
}