import { useState } from "react";
import { Link, useLoaderData } from "react-router-dom";
import EvjuSpaceInput from "../Layout/Components/FormElements/EvjuSpaceInput";
import EvjuSpaceButton from "../Layout/Components/FormElements/EvjuSpaceButton";
import { requestPasswordReset } from "../connectors/UserConnector";

function ForgotPassword() {

    const mail : any = useLoaderData();

    const [error, setError] = useState<string>("");
    const [username, setUsername] = useState<string>(mail);
    const [sent, setSent] = useState<boolean>(false);

    function formSubmitHandler(e : any) {
        e.preventDefault()
        setError("")

        if(!username || username == "") {
            setError("Bitte gib deine E-Mailadresse ein.")
        }
    
        requestPasswordReset(username)

        setSent(true)
    }

    return (
        <>
            {!sent ? <div className="login-container">
                <h1>Passwort vergessen</h1>
                {error && (<div>ERROR:<br /> {error}</div>)}
                <form onSubmit={formSubmitHandler}>
                    <EvjuSpaceInput onChange={setUsername} value={username} label="E-Mail" />
                    <EvjuSpaceButton class="login-button" text="Passwort zurücksetzen" onClick={() => {}} />

                </form>

                <div id="login-links">
                    <Link className="forgot-password-link login-link" to={"/login"}>Zurück zum Login</Link>
                </div>
            </div> : <div className="login-container">
                <h1>Passwort vergessen</h1>
                <p>Du bekommst eine Email von uns. Dort findest du einen Link, mit dem du dein Passwort zurücksetzen kannst.</p>
                <p>Sollte diese Email nicht innerhalb von fünf Minuten bei dir ankommen versuche es bitte erneut.</p>
                <div id="login-links">
                    <Link className="forgot-password-link login-link" to={"/login"}>Zurück zum Login</Link>
                </div>
            </div>}
        </>
    );
}

export default ForgotPassword;

export async function forgotPasswordLoader({ params } : any) {

    let mail = ""

    if(params.mail) {
        mail = params.mail
    }

    return mail;
}