import "./../../../style.css";
import 'remixicon/fonts/remixicon.css'
import Widget from "../../../Components/Widgets/Widget";
import { EvjuSpaceAgendaItem, EvjuSpaceEvent, EvjuSpaceEventGuest, EvjuSpaceEventSlim, getEvents, updateEvent } from "../../../../connectors/EventConnector";
import { useContext, useEffect, useState } from "react";
import { getUser } from "../../../../connectors/UserConnector";
import { UserContext } from "../../../../context/UserContext";
import EvjuSpaceButton from "../../../Components/FormElements/EvjuSpaceButton";
import { getInvitationPDF, getProtocolPDF } from "../../../../connectors/FileConnector";
import EvjuSpaceOverlay from "../../../Components/Overlay";
import { EvjuSpaceGroup } from "../../../../connectors/GroupConnector";
import EvjuSpaceInput from "../../../Components/FormElements/EvjuSpaceInput";

interface participationType {
    userID: string,
    firstName: string,
    lastName: string,
    isParticipating: boolean,
}

function EventControl(props : any) {

    const [userContext, setUserContext] = useContext<any>(UserContext);
    const event: EvjuSpaceEvent = props.event
    const group: EvjuSpaceGroup = props.group
    const [isParticipantsOverlayOpen, setIsParticipantsOverlayOpen] = useState<boolean>(false)
    const [participation, setParticipation] = useState<Array<participationType>>([{userID: "", firstName: "Loading", lastName: "...", isParticipating: false}])
    const [isHost, setIsHost] = useState<boolean>(false)
    const [isSecretary, setIsSecretary] = useState<boolean>(false)
    const [isProofreader, setIsProofreader] = useState<boolean>(false)

    const [isGuestOverlayOpen, setIsGuestOverlayOpen] = useState<boolean>(false)
    const [guests, setGuests] = useState<Array<EvjuSpaceEventGuest>>([{firstName: "Loading", lastName: "...", email: "loading"}])
    const [newGuestFirstName, setNewGuestFirstName] = useState<string>("")
    const [newGuestLastName, setNewGuestLastName] = useState<string>("")
    const [newGuestMail, setNewGuestMail] = useState<string>("")

    useEffect(() => {
        loadParticipants()
        if(event.guests) {
            setGuests(event.guests)
        }
    }, [])

    useEffect(() => {
        if(userContext.details) {
            if(group.isAdmin || (event.host && event.host?.indexOf(userContext.details?._id) > -1)) {
                setIsHost(true)
            }
        }
    }, [userContext])

    useEffect(() => {
        if(userContext.details) {
            if(isHost || (event.secretary && event.secretary?.indexOf(userContext.details?._id) > -1)) {
                setIsSecretary(true)
            }
        }
    }, [userContext, isHost])

    useEffect(() => {
        if(userContext.details) {
            if(isSecretary || (event.proofreader && event.proofreader?.indexOf(userContext.details?._id) > -1)) {
                setIsProofreader(true)
            }
        }
    }, [userContext, isSecretary])
    
    async function startEvent() {
        event.started = true;
        const response = await updateEvent(event);

        if(response._id) {
            window.location.href = '/group/' + event.group + '/event/' + event._id
        }
    }

    async function endEvent() {
        event.ended = true;
        const response = await updateEvent(event);

        if(response._id) {
            window.location.href = '/group/' + event.group + '/event/' + event._id
        }
    }

    function writeProtocol() {
        window.location.href = '/group/' + event.group + '/event/' + event._id + '/protocol'
    }

    async function loadParticipants() {
        let participants: [participationType] = [{userID: "", firstName: "Loading", lastName: "...", isParticipating: false}]
        if(group.members) {
            await Promise.all(group.members.map(async (member, i) => {
                let user = await getUser(member.id)
                let isParticipating = false
                if(event.participants) {
                    isParticipating =event.participants.includes(member.id)
                }
                if(user.firstName && user.lastName) {
                    if(participants[0].userID == "") {
                        participants = [{userID: member.id, firstName: user.firstName, lastName: user.lastName, isParticipating: isParticipating}]
                    } else {
                        participants.push({userID: member.id, firstName: user.firstName, lastName: user.lastName, isParticipating: isParticipating})
                    }
                }
            }))
        }
        await setParticipation(participants)
    }

    function toggleParticipation(uid: string, isParticipating: boolean) {
        setParticipation((prev) => prev.map((el, i) => {
            if(el.userID == uid) {
                el.isParticipating = isParticipating
            }
            return el
        }))

        if(isParticipating) {
            if(event.participants) {
                event.participants.push(uid)
            } else {
                event.participants = [uid]
            }
            
        } else {
            if(event.participants) {
                const index = event.participants.indexOf(uid)
                event.participants.splice(index, 1)
            }
        }

        updateEvent(event)
    }

    function openParticipationOverlay() {
        setIsParticipantsOverlayOpen(true)
    }

    function openGuestOverlay() {
        setIsGuestOverlayOpen(true)
        if(event.guests) {
            setGuests(event.guests)
        } else {
            setGuests([])
        }
    }

    function addGuest() {
        let newGuest: EvjuSpaceEventGuest = {firstName: newGuestFirstName, lastName: newGuestLastName, email: newGuestMail}
        if(event.guests) {
            event.guests.push(newGuest)
        } else {
            event.guests = [newGuest]
        }
        updateEvent(event)

        setGuests(guests.concat(newGuest))

        setNewGuestFirstName("")
        setNewGuestLastName("")
        setNewGuestMail("")
    }

    async function deleteGuest(guest: EvjuSpaceEventGuest) {
        let newGuests: Array<EvjuSpaceEventGuest> = []
        if(event.guests) {
            newGuests = event.guests
            const index = newGuests.indexOf(guest)
            newGuests.splice(index, 1)
        }

        await setGuests([])
        setGuests(newGuests)

        event.guests = newGuests
        updateEvent(event)
    }

    

    return (
        <Widget headerBox="Veranstaltungs-Funktionen">
            <EvjuSpaceOverlay isOpen={isParticipantsOverlayOpen} onClose={() => {setIsParticipantsOverlayOpen(!isParticipantsOverlayOpen)}}>
                <h2>Anwesenheiten</h2>
                <table>
                    <tr>
                        <th>Name</th>
                        <th>Anwesend</th>
                    </tr>
                    {participation.map((member: participationType, i) => {
                        return <>
                            <tr>
                                <td>{(member.firstName)} {(member.lastName)}</td>
                                <td>{member.isParticipating ?
                                    (<a className="toggle-button ri-toggle-fill" title="Anwesenheit einschalten" onClick={() => {toggleParticipation(member.userID, !member.isParticipating)}}></a>) :
                                    (<a className="toggle-button ri-toggle-line" title="Anwesenheit ausschalten" onClick={() => {toggleParticipation(member.userID, !member.isParticipating)}}></a>)}
                                </td>
                            </tr>
                        </>
                    })}
                </table>
            </EvjuSpaceOverlay>
            <EvjuSpaceOverlay isOpen={isGuestOverlayOpen} onClose={() => {setIsGuestOverlayOpen(!isGuestOverlayOpen)}}>
                <h2>Gäste</h2>
                <table>
                    <tr>
                        <th>Vorname</th>
                        <th>Nachname</th>
                        <th>E-mail</th>
                        <th></th>
                    </tr>
                    {guests.map((guest: EvjuSpaceEventGuest, i) => {
                        return <>
                            <tr>
                                <td>{guest.firstName}</td>
                                <td>{guest.lastName}</td>
                                <td>{guest.email}</td>
                                <td><a className="save_button ri-delete-bin-5-line" title="Speichern" onClick={() => {deleteGuest(guest)}}></a></td>
                            </tr>
                        </>
                    })}
                    <tr>
                        <td><EvjuSpaceInput value={newGuestFirstName} onChange={setNewGuestFirstName} /></td>
                        <td><EvjuSpaceInput value={newGuestLastName} onChange={setNewGuestLastName} /></td>
                        <td><EvjuSpaceInput value={newGuestMail} onChange={setNewGuestMail} /></td>
                        <td><a className="save_button ri-save-line" title="Speichern" onClick={addGuest}></a></td>
                    </tr>
                </table>
            </EvjuSpaceOverlay>
            <div className="eventcontrolcategory">
                <span className="eventcontrolcategory-label">Anwesenheiten</span>
                <div>
                    <div>
                        <br />Mitglieder: {isSecretary ? (<a className="widget-icon ri-edit-2-line" title="Bearbeiten" onClick={openParticipationOverlay}></a>) :""}
                        <br />
                        {participation.map((participant, i) => {
                            if(participant.isParticipating) {
                                return participant.firstName + " " + participant.lastName + ", "
                            }
                            return ""
                        })}
                    </div>
                </div>
                <div>
                    <div>
                        <br />Gäste: {isSecretary ? (<a className="widget-icon ri-edit-2-line" title="Bearbeiten" onClick={openGuestOverlay}></a>) : ""}
                        <br />
                        {guests.map((guest, i) => {
                            return guest.firstName + " " + guest.lastName + " <" + guest.email + ">, "
                        })}
                    </div>
                </div>
            </div>
            {isProofreader ?
                <div className="eventcontrolcategory">
                    <span className="eventcontrolcategory-label">Sitzungsfunktionen</span>
                    {isSecretary ? <>
                        {!event.started? <EvjuSpaceButton text="Veranstaltung starten" onClick={startEvent} /> : ""}
                        {event.started && !event.ended? <EvjuSpaceButton text="ToDo: Veranstaltung beenden" onClick={endEvent} /> : ""}
                    </> : ""}
                    {isProofreader ? <EvjuSpaceButton text="Protokoll schreiben" onClick={writeProtocol} /> : ""}
                </div>
            : ""}

            <div className="eventcontrolcategory">
                <span className="eventcontrolcategory-label">Downloads</span>
                <EvjuSpaceButton text="Einladung runterladen" onClick={() => {getInvitationPDF(event)}} />
                <EvjuSpaceButton text="Protokoll runterladen" onClick={() => {getProtocolPDF(event)}} />
            </div>
            
        </Widget>
    );

}
export default EventControl;