import { Link, useLoaderData } from "react-router-dom";
import { confirmEmail, getUserDetails } from "../connectors/UserConnector";

function EmailConfirmation() {

    const { user } : any = useLoaderData();

    return (
        <>
        <div className="login-container">
            <h1>Email bestätigt</h1>          
            <p>Hallo {user.firstName} {user.lastName}!</p>
            <p>Vielen Dank, dass du deine Email bestätigt hast.</p>
            <div id="login-links">
                <Link className="forgot-password-link login-link" to={"/login/" }>Weiter</Link>
            </div>
        </div>

        </>
    );
}

export default EmailConfirmation;

export async function emailConfirmationLoader({ params } : any) {

    const user = await confirmEmail(params.userid, params.username)
  
    return { user };
  }