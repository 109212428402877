import { useContext, useEffect, useState } from "react";
import { useLoaderData } from "react-router-dom";
import Card from "../../../Components/Card";
import { EvjuSpaceEvent, getEvent } from "../../../../connectors/EventConnector";
import { dateToString } from "../../../../Helper/DateFunctions";
import Widget from "../../../Components/Widgets/Widget";
import WidgetArea from "../../../Components/Widgets/WidgetArea";
import { EvjuSpaceGroup, getGroup } from "../../../../connectors/GroupConnector";
import AgendaWidget from "../../../Components/Widgets/AgendaWidget";
import EventControl from "./EventControl";
import { UserContext } from "../../../../context/UserContext";
import { getUser } from "../../../../connectors/UserConnector";

function EventDetails() {

    const [userContext, setUserContext] = useContext<any>(UserContext);
    const { group, event } : any = useLoaderData();
    const [isEditable, setIsEditable] = useState<boolean>(false)
    const [host, setHost] = useState<string>("")
    const [secretary, setSecretary] = useState<string>("")
    const [proofreader, setProofreader] = useState<string>("")

    useEffect(() => {
        const fetchData = async () => {
            setHost(await getName(event.host))
            setSecretary(await getName(event.secretary))
            setProofreader(await getName(event.proofreader))
        }
        fetchData()
    }, [])

    useEffect(() => {
        if(userContext.details) {
            if(group.isAdmin || isHost()) {
                setIsEditable(true)
            }
        }
    }, [userContext])

    function isHost(): boolean {
         return event.host.indexOf(userContext.details?._id) > -1
    }

    async function getName(userIDs: Array<string>): Promise<string> {
        let names = ""

        for(const userID of userIDs) {
            const user = await getUser(userID)
            if(names !== "") {
                names += ", "
            }
            names += user.firstName + " " + user.lastName
        }
        
        return names
    }


    return (
        <Card heading={group.name} headerBox={event.name} back={"/group/" + group._id} settings={isEditable ? ("/group/" + group._id + "/event/" + event._id + "/settings/") : ""}>
            <p>{event.description}</p>

            <p>Veranstaltungsbeginn: {dateToString(event.starttime)}</p>

            {event.location.isOffline == true && (
                    <div>
                        <div>
                            Veranstaltungsort: {event.location.offline.name} 
                            {event.location.offline.street && event.location.offline.number &&
                                event.location.offline.postalcode && event.location.offline.city ?
                                ` (${event.location.offline.street} ${event.location.offline.number}, ${event.location.offline.postalcode} ${event.location.offline.city} 
                                ${event.location.offline.room ? "Raum: " + event.location.offline.room : ""} )` : ""}
                            <br />
                            {event.location.offline.description}
                        </div>
                    </div>
            )}

            {event.location.isOnline == true && (
                    <div>
                        <div>
                            Veranstaltungsort: {event.location.online.name} <br />
                            {event.location.online.description}
                        </div>
                        <div>
                            Link:<br />
                            {event.location.online.link}
                        </div>
                    </div>
            )}

            {event.host ? (
                <p>Sitzungsleitung: {host}</p>
            ) : ""}

            {event.secretary ? (
                <p>Protokoll schreiben: {secretary} </p>
            ) : ""}

            {event.proofreader ? (
                <p>Protokoll korrekturlesen: {proofreader} </p>
            ) : ""}

            <WidgetArea columns="1">
                <AgendaWidget event={event} editable={isEditable} />
                <EventControl event={event} group={group} />
            </WidgetArea>
        </Card>
    );
}
export default EventDetails;

export async function eventDetailsLoader({ params } : any) {
    const group:EvjuSpaceGroup = await getGroup(params.groupid)

    const event:EvjuSpaceEvent = await getEvent(params.eventid)
    return { group, event };
}
