import { useEffect, useState } from "react";
import { useLoaderData, useOutletContext } from "react-router-dom";
import Card from "../../../Components/Card";
import EvjuSpaceCheckbox from "../../../Components/FormElements/EvjuSpaceCheckbox";
import EvjuSpaceInput from "../../../Components/FormElements/EvjuSpaceInput";
import EvjuSpaceDateTime from "../../../Components/FormElements/EvjuSpaceDateTime";
import EvjuSpaceButton from "../../../Components/FormElements/EvjuSpaceButton";
import { createEvent } from "../../../../connectors/EventConnector";
import EvjuSpaceUserSelector from "../../../Components/FormElements/EvjuSpaceUserSelector";
import { getGroup } from "../../../../connectors/GroupConnector";
import { start } from "repl";

function CreateEvent() {

    const { group } : any = useLoaderData();

    const [userSet, setUserSet] = useState<Array<string>>()

    const [name, setName] = useState<string>("");
    const [description, setDescription] = useState<string>();
    const [starttime, setStarttime] = useState<Date>(new Date());
    const [endtime, setEndtime] = useState<Date>(new Date());
    const [host, setHost] = useState<Array<string>>();
    const [secretary, setSecratary] = useState<Array<string>>();
    const [proofreader, setProofreader] = useState<Array<string>>();
    const [isOffline, setIsOffline] = useState<boolean>(false);
    const [offlineName, setOfflineName] = useState<string>("");
    const [offlineDescription, setOfflineDescription] = useState<string>();
    const [offlineStreet, setOfflineStreet] = useState<string>();
    const [offlineNumber, setOfflineNumber] = useState<string>();
    const [offlinePostalcode, setOfflinePostalcode] = useState<string>();
    const [offlineCity, setOfflineCity] = useState<string>();
    const [offlineRoom, setOfflineRoom] = useState<string>();
    const [isOnline, setIsOnline] = useState<boolean>(false);
    const [onlineName, setOnlineName] = useState<string>("");
    const [onlineDescription, setOnlineDescription] = useState<string>();
    const [onlineLink, setOnlineLink] = useState<string>();
    
    useEffect(() => {
        console.log("!")
        console.log(starttime)
        if(!group.isAdmin) {
            window.location.replace("/group/" + group._id);
        }

        let newUserSet: Array<string> = []
        for(const member of group.members) {
            newUserSet.push(member.id)
        }
        setUserSet(newUserSet)
    }, [])

    async function onSave() {
        const response = await createEvent(
            {
                name: name,
                description: description,
                starttime: starttime,
                started: false,
                endtime: endtime,
                ended: false,
                location: {
                    isOffline: isOffline,
                    offline: {
                        name: offlineName,
                        description: offlineDescription,
                        street: offlineStreet,
                        number: offlineNumber,
                        postalcode: offlinePostalcode,
                        city: offlineCity,
                        room: offlineRoom,
                    },
                    isOnline: isOnline,
                    online: {
                        name: onlineName,
                        description: onlineDescription,
                        link: onlineLink,
                    },
                },
                group: group._id,
                host: host? host : undefined,
                secretary: secretary? secretary : undefined,
                proofreader: proofreader? proofreader : undefined,
            }
        );

        if(response._id) {
            window.location.href = '/group/' + response.group + '/event/' + response._id
        }

    }

    return (
        <Card heading="Termin erstellen" headerBox={ group._id } back={"/group/" + group._id}>
            <EvjuSpaceInput label="Name der Veranstaltung" value={name} onChange={setName} />
            <EvjuSpaceInput label="Beschreibung der Veranstaltung" value={description} onChange={setDescription} />
            <EvjuSpaceDateTime label="Beginn der Veranstaltung" value={starttime} onChange={setStarttime} />
            <EvjuSpaceDateTime label="Ende der Veranstaltung" value={endtime} onChange={setEndtime} />
            <EvjuSpaceUserSelector label={"Sitzungsleiter"} onChange={setHost} set={userSet} />
            <EvjuSpaceUserSelector label={"Protokoll schreiben"} onChange={setSecratary}set={userSet} />
            <EvjuSpaceUserSelector label={"Protokoll korrekturlesen"} onChange={setProofreader}set={userSet} />

            <EvjuSpaceCheckbox label="Die Veranstaltung findet Offline statt." value={isOffline} onChange={setIsOffline} />
            {isOffline ? <EvjuSpaceInput label="Name des Ortes" value={offlineName} onChange={setOfflineName} /> : ""}
            {isOffline ? <EvjuSpaceInput label="Beschreibung des Ortes" value={offlineDescription} onChange={setOfflineDescription} /> : ""}
            {isOffline ? <EvjuSpaceInput label="Straße" value={offlineStreet} onChange={setOfflineStreet} /> : ""}
            {isOffline ? <EvjuSpaceInput label="Nummer" value={offlineNumber} onChange={setOfflineNumber} /> : ""}
            {isOffline ? <EvjuSpaceInput label="PLZ" value={offlinePostalcode} onChange={setOfflinePostalcode} /> : ""}
            {isOffline ? <EvjuSpaceInput label="Ort" value={offlineCity} onChange={setOfflineCity} /> : ""}
            {isOffline ? <EvjuSpaceInput label="Raum" value={offlineRoom} onChange={setOfflineRoom} /> : ""}

            <EvjuSpaceCheckbox label="Die Veranstaltung findet Online statt." value={isOnline} onChange={setIsOnline} />
            {isOnline ? <EvjuSpaceInput label="Name des Ortes" value={onlineName} onChange={setOnlineName} /> : ""}
            {isOnline ? <EvjuSpaceInput label="Beschreibung des Ortes" value={onlineDescription} onChange={setOnlineDescription} /> : ""}
            {isOnline ? <EvjuSpaceInput label="Link" value={onlineLink} onChange={setOnlineLink} /> : ""}
            <EvjuSpaceButton text="Speichern" onClick={onSave} />
        </Card>
    );
}
export default CreateEvent;

export async function createEventLoader({ params } : any) {
    //TODO: GROUP AUS DB LADEN
    const group = await getGroup(params.id)
    return { group };
}
