import { access } from "fs"

export interface EvjuSpaceEvent {
    _id?: string,
    name: string,
    description?: string,
    starttime: Date,
    started: Boolean,
    actualstarttime?: Date,
    endtime: Date,
    actualendtime?: Date,
    ended: Boolean,
    location: EvjuSpaceLocation,
    agenda?: EvjuSpaceAgendaItem[],
    agendaLocked?: Boolean,
    group: string,
    host?: string[],
    secretary?: string[],
    proofreader?: string[],
    participants?: string[],
    guests?: EvjuSpaceEventGuest[],
    children?: JSX.Element|JSX.Element[]
}

export interface EvjuSpaceEventSlim {
    _id?: string,
    name: string,
    starttime: Date,
    children?: JSX.Element|JSX.Element[]
}

export interface EvjuSpaceLocation {
    isOffline: boolean,
    isOnline: boolean,
    offline?: EvjuSpaceLocationOffline,
    online?: EvjuSpaceLocationOnline,
}

export interface EvjuSpaceLocationOffline {
    name: string,
    description?: string,
    street?: string,
    number?: string,
    postalcode?: string,
    city?: string,
    room?: string,
}

export interface EvjuSpaceLocationOnline {
    name: string,
    description?: string,
    link?:string,
}

export interface EvjuSpaceProtocolItem {
    decision: boolean,
    text: string,
    votingresult?: string,
}

export interface EvjuSpaceAgendaItem {
    name: string,
    description: string,
    protocol?: EvjuSpaceProtocolItem[],
    subAgenda?: EvjuSpaceSubAgendaItem[],
}

export interface EvjuSpaceSubAgendaItem {
    name: string,
    description: string,
    protocol?: EvjuSpaceProtocolItem[],
}

export interface EvjuSpaceEventGuest {
    firstName: string,
    lastName: string,
    email: string,
}

export async function createEvent(event:EvjuSpaceEvent) {
    let validatedEvent:EvjuSpaceEvent = {
        name: event.name,
        starttime: event.starttime,
        started: false,
        endtime: event.endtime,
        ended: false,
        location: {
            isOffline: event.location.isOffline,
            isOnline: event.location.isOnline,
        },
        group: event.group,
    }

    if(event.description) {
        validatedEvent.description = event.description
    }
    
    if(event.location.online?.name) {
        validatedEvent.location.online = {
            name: event.location.online.name,
        }

        if(event.location.online.description) {
            validatedEvent.location.online.description = event.location.online.description
        }
        
        if(event.location.online.link) {
            validatedEvent.location.online.link = event.location.online.link
        }
    }

    if(event.location.offline?.name) {
        validatedEvent.location.offline = {
            name: event.location.offline.name,
        }

        if(event.location.offline.description) {
            validatedEvent.location.offline.description = event.location.offline.description
        }
        
        if(event.location.offline.street) {
            validatedEvent.location.offline.street = event.location.offline.street
        }
        
        if(event.location.offline.number) {
            validatedEvent.location.offline.number = event.location.offline.number
        }
        
        if(event.location.offline.postalcode) {
            validatedEvent.location.offline.postalcode = event.location.offline.postalcode
        }

        if(event.location.offline.city) {
            validatedEvent.location.offline.city = event.location.offline.city
        }
        
        if(event.location.offline.room) {
            validatedEvent.location.offline.room = event.location.offline.room
        }
    }

    if(event.host) {
        validatedEvent.host = event.host
    }

    if(event.secretary) {
        validatedEvent.secretary = event.secretary
    }

    if(event.proofreader) {
        validatedEvent.proofreader = event.proofreader
    }

    const response = await fetch(process.env.REACT_APP_EVENT_SERVICE + "events/createEvent", {
        method: "POST",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
            event: validatedEvent,
        }),
    })

    if (response.ok) {
        const responsejson = await response.json()
        return responsejson;
    } else {
        console.error(response)
    }
}

export async function updateEvent(event:EvjuSpaceEvent) {
    let validatedEvent:EvjuSpaceEvent = {
        _id:event._id,
        name: event.name,
        starttime: event.starttime,
        started: event.started,
        endtime: event.endtime,
        ended: event.ended,
        location: {
            isOffline: event.location.isOffline,
            isOnline: event.location.isOnline,
        },
        group: event.group,
    }

    if(event.description) {
        validatedEvent.description = event.description
    }
    
    if(event.location.online?.name) {
        validatedEvent.location.online = {
            name: event.location.online.name,
        }

        if(event.location.online.description) {
            validatedEvent.location.online.description = event.location.online.description
        }
        
        if(event.location.online.link) {
            validatedEvent.location.online.link = event.location.online.link
        }
    }

    if(event.location.offline?.name) {
        validatedEvent.location.offline = {
            name: event.location.offline.name,
        }

        if(event.location.offline.description) {
            validatedEvent.location.offline.description = event.location.offline.description
        }
        
        if(event.location.offline.street) {
            validatedEvent.location.offline.street = event.location.offline.street
        }
        
        if(event.location.offline.number) {
            validatedEvent.location.offline.number = event.location.offline.number
        }
        
        if(event.location.offline.postalcode) {
            validatedEvent.location.offline.postalcode = event.location.offline.postalcode
        }

        if(event.location.offline.city) {
            validatedEvent.location.offline.city = event.location.offline.city
        }
        
        if(event.location.offline.room) {
            validatedEvent.location.offline.room = event.location.offline.room
        }
    }

    if(event.host) {
        validatedEvent.host = event.host
    }

    if(event.secretary) {
        validatedEvent.secretary = event.secretary
    }

    if(event.proofreader) {
        validatedEvent.proofreader = event.proofreader
    }

    if(event.participants) {
        validatedEvent.participants = event.participants
    }

    if(event.guests) {
        validatedEvent.guests = event.guests
    }

    if(event.actualstarttime) {
        validatedEvent.actualstarttime = event.actualstarttime
    }

    if(event.actualendtime) {
        validatedEvent.actualendtime = event.actualendtime
    }

    const response = await fetch(process.env.REACT_APP_EVENT_SERVICE + "events/updateEvent", {
        method: "POST",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
            event: validatedEvent,
        }),
    })

    if (response.ok) {
        const responsejson = await response.json()
        return responsejson;
    } else {
        console.error(response)
        return response;
    }
}

export async function deleteEvent(eventID: string):Promise<boolean> {
    const response = await fetch(process.env.REACT_APP_EVENT_SERVICE + "events/deleteEvent", {
        method: "DELETE",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
            eventID: eventID,
        }),
        
    })
    
    return true;
}

export async function getEvents(groupID: string):Promise<EvjuSpaceEventSlim[]> {
    let data:EvjuSpaceEventSlim[] = [{_id: "0", name: "Loading...", starttime: new Date()}]

    const response = await fetch(process.env.REACT_APP_EVENT_SERVICE + "events/getEvents/" + groupID, {
        method: "GET",
        credentials: "include",
        // Pass authentication token as bearer token in header
        headers: {
            "Content-Type": "application/json",
        },
    })
    
    data = await response.json()
    return data
}

export async function getEvent(eventID: string):Promise<EvjuSpaceEvent> {
    const response = await fetch(process.env.REACT_APP_EVENT_SERVICE + "events/getEvent/" + eventID, {
        method: "GET",
        credentials: "include",
        // Pass authentication token as bearer token in header
        headers: {
            "Content-Type": "application/json",
        },
    })
    
    const data = await response.json()
    return data
}

export async function updateAgenda(eventID:string, agenda: EvjuSpaceAgendaItem[]) {
    const response = await fetch(process.env.REACT_APP_EVENT_SERVICE + "events/updateAgenda/" + eventID, {
        method: "POST",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
            agenda: agenda,
        }),
    })

    if (response.ok) {
        const responsejson = await response.json()
        return responsejson;
    } else {
        console.error(response)
    }
}
