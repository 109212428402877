export interface EvjuSpaceGroupMember {
    _id: string,
    id: string,
}

export interface EvjuSpaceGroup {
    _id: string,
    name: string,
    description?: string,
    server?: string,
    isAdmin?: boolean,
    protocolHeadline?: string,
    invitationHeadline?: string,
    invitationFirstText?: string,
    invitationSecondText?: string,
    members?: EvjuSpaceGroupMember[],
    children?: JSX.Element|JSX.Element[]
}

export async function newGroup(name:string, admin:string, server:string) {

    if(name === "" || admin === "") {
        return "Data Missing"
    }
    
    const response = await fetch(process.env.REACT_APP_SERVER_SERVICE + "group/newgroup", {
        method: "POST",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
            name: name,
            admin: admin,
            server: server,
        }),
    })

    if (response.ok) {
        const responsejson = await response.json()
        return responsejson;
    } else {
        console.error(response)
    }
}

export async function getGroup(groupID: string):Promise<EvjuSpaceGroup> {
    let data:EvjuSpaceGroup = {_id: "0", name: "ERROR"}

    const response = await fetch(process.env.REACT_APP_SERVER_SERVICE + "group/group/" + groupID, {
        method: "GET",
        credentials: "include",
        // Pass authentication token as bearer token in header
        headers: {
            "Content-Type": "application/json",
        },
    })
    
    data = await response.json()
    return data
}

export async function updateGroup(group:EvjuSpaceGroup) {
    const response = await fetch(process.env.REACT_APP_SERVER_SERVICE + "group/update", {
        method: "POST",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
            groupid: group._id,
            group: group,
        }),
    })

    if (response.ok) {
        return true;
    } else {
        return false;
    }
}

export async function addMemberToGroup(userid:string, groupid:string) {

    if(groupid === "" || userid === "") {
        return "Data Missing"
    }
    
    const response = await fetch(process.env.REACT_APP_SERVER_SERVICE + "group/addmember", {
        method: "POST",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
            // serverid: serverid,
            groupid: groupid,
            userid: userid,
        }),
    })

    if (response.ok) {
        return true;
    } else {
        return false;
    }
}

export async function removeMemberFromGroup(userid:string, groupid:string) {

    if(groupid === "" || userid === "") {
        return "Data Missing"
    }
    
    const response = await fetch(process.env.REACT_APP_SERVER_SERVICE + "group/removemember", {
        method: "POST",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
            // serverid: serverid,
            groupid: groupid,
            userid: userid,
        }),
    })

    if (response.ok) {
        return true;
    } else {
        return false;
    }
}