import Card from "../Components/Card";
import Widget from "../Components/Widgets/Widget";
import WidgetArea from "../Components/Widgets/WidgetArea";

function Dashboard() {
  return (
      <Card heading="Dashboard">
        <WidgetArea>
          <Widget headerBox="Willkommen..." standardHeight={true}>
            <p>...bei evju.space! </p>
            <p>Wenn du links im Menü noch nichts sehen kannst (außer Dashboard, Einstellungen und Logout) melde dich bitte bei der Person, die dich auf EvJu.Space aufmerksam gemacht hat.</p>
            <p>Diese Person kann dich zu Servern und Gruppen hinzufügen und dadurch diese Seiten mit Inhalt füllen.</p>
          </Widget>
          <Widget headerBox="Termine" standardHeight={true}>
            <p>Coming soon...</p>
          </Widget>
          <Widget headerBox="ToDos" standardHeight={true}>
            <p>Coming soon...</p>
          </Widget>
        </WidgetArea>
      </Card>
  );
}

export default Dashboard;