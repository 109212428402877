import { useCallback, useContext, useEffect, useState } from "react";
import { Link, useLoaderData } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import EvjuSpaceInput from "../Layout/Components/FormElements/EvjuSpaceInput";
import EvjuSpaceButton from "../Layout/Components/FormElements/EvjuSpaceButton";
import { getSetting } from "../connectors/SettingsConnector";
import { getUserDetails } from "../connectors/UserConnector";
import EvjuSpaceOverlay from "../Layout/Components/Overlay";
import { resendEmailConfirmation } from "../connectors/EmailConnector";

function Login() {

  const { registrationEnabled } : any = useLoaderData();

  const [error, setError] = useState<String>("");
  const [username, setUsername] = useState<String>("");
  const [password, setPassword] = useState<String>("");
  const [userContext, setUserContext] = useContext<any>(UserContext)
  const [isEmailConfirmationOverlayOpen, setIsEmailConfirmationOverlayOpen] = useState<boolean>(false)
  const [isEmailConfirmationResentOverlayOpen, setIsEmailConfirmationResentOverlayOpen] = useState<boolean>(false)
  const [emailConfirmationOverlayDisplayed, setEmailConfirmationOverlayDisplayed] = useState<boolean>(false)

  const verifyUser = useCallback(() => {
    fetch(process.env.REACT_APP_USER_SERVICE + "users/refreshToken", {
      method: "POST",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    }).then(async response => {
      if (response.ok) {
        const data = await response.json()
        setUserContext((oldValues : any) => {
          return { ...oldValues, token: data.token }
        })
      } else {
        setUserContext((oldValues : any) => {
          return { ...oldValues, token: null }
        })
      }
      // call refreshToken every 5 minutes to renew the authentication token.
      setTimeout(verifyUser, 5 * 60 * 1000)
    })
  }, [setUserContext])

  useEffect(() => {
    verifyUser()
  }, [verifyUser])

  useEffect(() => {
    if(error != "") {
      console.error(error)
    }
  }, [error])

  function formSubmitHandler(e : any) {
    e.preventDefault()
    setError("")

    const genericErrorMessage = "Something went wrong! Please try again later."

    const lowerCaseUsername = username.toLowerCase()
console.log(lowerCaseUsername)
    fetch(process.env.REACT_APP_USER_SERVICE + "users/login", {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ 'username': lowerCaseUsername, 'password': password }),
    }).then(async response => {
        if (!response.ok) {
          if (response.status === 400) {
            setError("Bitte füll alle Felder aus.")
          } else if (response.status === 401) {
            setError("Der Benutzername oder das Passwort ist nicht richtig.")
          } else {
            setError(genericErrorMessage)
          }
        } else {
          const data = await response.json()
          await setUserContext((oldValues : any) => {
            return { ...oldValues, token: data.token }
          })
        }
      })
      .catch(error => {
        setError(genericErrorMessage)
      })
  }

  useEffect(() => {
    if(userContext.token) {
      getUserDetails().then(async data => {
        await setUserContext((oldValues : any) => {
          return { ...oldValues, details: data }
        })
      })
    }
  }, [userContext.token])

  useEffect(() => {
    if(userContext.details) {
      if(false) { //AGB bestätigt? --> if(getCurrentTermsOfUseVersion() > userContext.details.termsOfUseAgreed)

      } else if(false) { // Datenschutz bestätigt? --> if(getCurrentdataProtectionConditionVersion() > userContext.details.dataProtectionConditionAgreed)

      } else if(!userContext.details.emailConfirmed && !emailConfirmationOverlayDisplayed) {
        setIsEmailConfirmationOverlayOpen(true)
      } else {
        window.location.replace("/");
      }
    }
  }, [userContext.details, emailConfirmationOverlayDisplayed])

  async function sendEmailConfirmation() {

    await resendEmailConfirmation()

    setIsEmailConfirmationOverlayOpen(false)
    setIsEmailConfirmationResentOverlayOpen(true)
  }

  function closeEmailConfirmationOverlay() {
    setIsEmailConfirmationOverlayOpen(false)
    setEmailConfirmationOverlayDisplayed(true)
  }

  function closeEmailConfirmationResentOverlay() {
    setIsEmailConfirmationResentOverlayOpen(false)
    setEmailConfirmationOverlayDisplayed(true)
  }

  return (
    <>
      <div className="login-container">
        <h1>Login</h1>
          {error && (<div className="login-error">Fehler:<br /> {error}</div>)}
          <form onSubmit={formSubmitHandler}>
            <EvjuSpaceInput onChange={setUsername} value={username} label="E-Mail" />
            <EvjuSpaceInput onChange={setPassword} value={password} type="password" label="Passwort" />
            <EvjuSpaceButton class="login-button" text="Einloggen" onClick={() => {}} />

          </form>

          <div id="login-links">
            <Link className="forgot-password-link login-link" to={"/forgotPassword/" + username}>Passwort vergessen</Link>
            {registrationEnabled? <Link className="register-link login-link" to={"/register"}>Neu Registrieren</Link> : "" }
          </div>
      </div>

      <EvjuSpaceOverlay isOpen={isEmailConfirmationOverlayOpen} onClose={closeEmailConfirmationOverlay}>
        <>
          <h1>Bitte bestätige deine Emailadresse</h1>
          <h3>{userContext.details?.username && userContext.details?.username}</h3>
          <p>Ist das deine Emailadresse? Sie wurde leider noch nicht bestätigt. Bei der Registrierung haben wir dir dafür eine Email zugeschickt. Bitte klicke den Link in der Email an.</p>
          <p>Solltest du die Email nicht bekommen haben können wir dir eine neue Email schicken. klicke dafür bitte <Link to={"#"} onClick={sendEmailConfirmation}>hier.</Link></p>
          <p>Sollte die Email neue Email auch nicht ankommen oder die Mailadresse oben ist falsch <Link to={"/contact"} >melde dich bitte bei uns!</Link></p>
          <EvjuSpaceButton text="Okay" onClick={closeEmailConfirmationOverlay} />
        </>
      </EvjuSpaceOverlay>
      <EvjuSpaceOverlay isOpen={isEmailConfirmationResentOverlayOpen} onClose={closeEmailConfirmationResentOverlay} >
        <>
          <h1>Bitte bestätige deine Emailadresse</h1>
          <p>Die Email wurde erneut gesendet.</p>
          <EvjuSpaceButton text="Okay" onClick={closeEmailConfirmationResentOverlay} />
        </>
      </EvjuSpaceOverlay>
    </>
  );
}

export default Login;

export async function loginLoader({ params } : any) {

  const registrationEnabled = await getSetting('registrationEnabled') == 'true'

  return { registrationEnabled };
}