import { useCallback, useContext, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { UserContext, UserProvider } from './context/UserContext';
import Sidebar from './Layout/Sidebar/Sidebar';
import Loader from './Loader';
import { getUserDetails } from './connectors/UserConnector';

function App() {

  const [userContext, setUserContext] = useContext<any>(UserContext);

  const verifyUser = useCallback(() => {
    fetch(process.env.REACT_APP_USER_SERVICE + "users/refreshToken", {
      method: "POST",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    }).then(async response => {
      if (response.ok) {
        const data = await response.json()
        setUserContext((oldValues : any) => {
          return { ...oldValues, token: data.token }
        })
        if(!userContext.details) {
          // fetchUserDetails()
        }
      } else {
        // alert("Fehler 38202347 - Bitte rufen Sie umgehend 015778228166 an und geben Sie diesen Fehlercode durch!")
        setUserContext((oldValues : any) => {
          return { ...oldValues, token: null }
        })
      }
      // call refreshToken every 5 minutes to renew the authentication token.
      setTimeout(verifyUser, 5 * 60 * 1000)
    })
  }, [setUserContext])

  useEffect(() => {
    verifyUser()
  }, [verifyUser])

  function logoutHandler() {
    fetch(process.env.REACT_APP_USER_SERVICE + "users/logout", {
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userContext.token}`,
      },
    }).then(async response => {
      setUserContext((oldValues : any) => {
        return { ...oldValues, details: undefined, token: null }
      })
      window.localStorage.setItem("logout", "" + Date.now())
    })
  }

  /**
   * Sync logout across tabs
   */

  const syncLogout = useCallback((event : any) => {
    if (event.key === "logout") {
      // If using react-router-dom, you may call history.push("/")
      window.location.reload()
    }
  }, [])

  useEffect(() => {
    window.addEventListener("storage", syncLogout)
    return () => {
      window.removeEventListener("storage", syncLogout)
    }
  }, [syncLogout])

  const fetchUserDetails = useCallback(() => {
    // fetch(process.env.REACT_APP_USER_SERVICE + "users/me", {
    //   method: "GET",
    //   credentials: "include",
    //   // Pass authentication token as bearer token in header
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ${userContext.token}`,
    //   },
    // }).then(async response => {
    //   if (response.ok) {
    //     const data = await response.json()
    //     setUserContext((oldValues : any) => {
    //       return { ...oldValues, details: data }
    //     })
    //   } else {
    //     if (response.status === 401) {
    //       // Edge case: when the token has expired.
    //       // This could happen if the refreshToken calls have failed due to network error or
    //       // User has had the tab open from previous day and tries to click on the Fetch button
    //       window.location.reload()
    //     } else {
    //       setUserContext((oldValues : any) => {
    //         return { ...oldValues, details: null }
    //       })
    //     }
    //   }
    // })

    getUserDetails().then(data => {
      setUserContext((oldValues : any) => {
        return { ...oldValues, details: data }
      })
    })
  }, [setUserContext, userContext.token])


  useEffect(() => {
    // fetch only when user details are not present
    if (!userContext.details && userContext.token) {
      fetchUserDetails()
    }
  }, [userContext.details, fetchUserDetails])

  const refetchHandler = () => {
    // alert("refetchHandler")
    // set details to undefined so that spinner will be displayed and
    //  fetchUserDetails will be invoked from useEffect
    setUserContext((oldValues : any) => {
      return { ...oldValues, details: undefined }
    })
  }

  if(userContext.token === null) {
    window.location.replace("/login");
  }

  return userContext.token ? (
    <>
      <Sidebar logout={logoutHandler} />
      <Outlet context={[userContext, setUserContext]}/>
    </>
  ) : (
    <Loader />
  );
}

export default App;