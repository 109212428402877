import { useLoaderData, useOutletContext } from "react-router-dom";
import Card from "../../../../Components/Card";
import { EvjuSpaceAgendaItem, EvjuSpaceEvent, EvjuSpaceProtocolItem, deleteEvent, getEvent, updateAgenda, updateEvent } from "../../../../../connectors/EventConnector";
import WidgetArea from "../../../../Components/Widgets/WidgetArea";
import ProtocolItemWidget from "./ProtocolItemWidget";
import { useEffect, useState } from "react";
import Widget from "../../../../Components/Widgets/Widget";
import EvjuSpaceInput from "../../../../Components/FormElements/EvjuSpaceInput";
import EvjuSpaceDateTime from "../../../../Components/FormElements/EvjuSpaceDateTime";

function EventProtocol() {

    const event :any = useLoaderData();
    const [agenda, setAgenda] = useState<EvjuSpaceAgendaItem[]>(event.agenda)
    const [editableAgendaItem, setEditableAgendaItem] = useState<number>(-1)
    const [actualStarttime, setActualStarttime] = useState<Date>(new Date(event.actualstarttime? event.actualstarttime : event.starttime));
    const [actualEndtime, setActualEndtime] = useState<Date>(new Date(event.actualendtime? event.actualendtime : event.endtime));

    useEffect(() => {
        updateAgenda(event._id, agenda)
    }, [agenda])

    useEffect(() => {
        event.actualstarttime = actualStarttime
        console.log(event)
        updateEvent(event)
    }, [actualStarttime])

    useEffect(() => {
        event.actualendtime = actualEndtime
        updateEvent(event)
    }, [actualEndtime])

    async function updateProtocol(agendaItemNumber: number, protocolItems: EvjuSpaceProtocolItem[]) {
        const currentEvent = await getEvent(event._id)
        if(currentEvent.agenda) {
            const nextAgenda = currentEvent.agenda.map((agendaItem, i) => {
                if(i == agendaItemNumber) {
                    agendaItem.protocol = protocolItems
                }
                return agendaItem
            })
            setAgenda(nextAgenda)
        }   
    }

    async function updateSubAgendaProtocol(agendaItemNumber: number, subAgendaItemNumber: number, protocolItems: EvjuSpaceProtocolItem[]) {
        const currentEvent = await getEvent(event._id)
        if(currentEvent.agenda) {
            const nextAgenda = agenda.map((agendaItem, i) => {
                if(i == agendaItemNumber) {
                    const nextSubAgenda = agendaItem.subAgenda?.map((subAgendaItem, j) => {
                        if(j == subAgendaItemNumber) {
                            subAgendaItem.protocol = protocolItems;
                        }
                        return subAgendaItem
                    })
                }
                return agendaItem
            })
            setAgenda(nextAgenda)
        }
    }

    return (
        <>
            <Card heading={event? event.name : "Loading..."} headerBox="Protokoll schreiben" back={event._id && event.group ? "/group/" + event.group + "/event/" + event._id : "#"} >
                <Widget headerBox="Zeiten">
                    <EvjuSpaceDateTime label="Beginn der Veranstaltung" value={actualStarttime} onChange={setActualStarttime} />
                    <EvjuSpaceDateTime label="Ende der Veranstaltung" value={actualEndtime} onChange={setActualEndtime} />
                </Widget>
                <WidgetArea columns="1">
                    {agenda != null ? agenda.map((agendaItem: EvjuSpaceAgendaItem, i:number) => {
                        return (
                            <ProtocolItemWidget key={"agendeItem"+i} agendaItem={agendaItem} agendaItemNumber={i} updateProtocol={updateProtocol} updateSubAgendaProtocol={updateSubAgendaProtocol} editableAgendaItem={editableAgendaItem} setEditableAgendaItem={setEditableAgendaItem} />
                        );
                    }) : ""}
                </WidgetArea>
            </Card>
        </>
    );
}
export default EventProtocol;

export async function eventProtocolLoader({ params } : any) {

    const event:EvjuSpaceEvent = await getEvent(params.eventid)
    return event;
}