import { EvjuSpaceGroup } from "./GroupConnector"

export interface EvjuSpaceServer {
    _id: string,
    name: string,
    isAdmin?: boolean,
    admins?: EvjuSpaceServerMember[],
    groups: EvjuSpaceGroup[],
    members?: EvjuSpaceServerMember[],
    applicants?: EvjuSpaceServerMember[],
    children?: JSX.Element|JSX.Element[],
}

export interface EvjuSpaceServerMember {
    _id: string,
    id: string,
}

export async function getServerList():Promise<EvjuSpaceServer[]> {
    let data:EvjuSpaceServer[] = [
        {_id: "0", name: "Loading...", groups: []}
    ]

    const response = await fetch(process.env.REACT_APP_SERVER_SERVICE + "server/serverList", {
        method: "GET",
        credentials: "include",
        // Pass authentication token as bearer token in header
        headers: {
            "Content-Type": "application/json",
        },
    })
    
    data = await response.json()
    return data
    
}

export async function getPossibleServerApplicationList():Promise<EvjuSpaceServer[]> {
    let data:EvjuSpaceServer[] = [
        {_id: "0", name: "Loading...", groups: []}
    ]

    const response = await fetch(process.env.REACT_APP_SERVER_SERVICE + "server/listPossibleApplications", {
        method: "GET",
        credentials: "include",
        // Pass authentication token as bearer token in header
        headers: {
            "Content-Type": "application/json",
        },
    })
    
    data = await response.json()
    return data   
}

export async function applyToServer(serverid:string) {

    if(serverid === "") {
        throw new Error("Data missing")
    }
    
    const response = await fetch(process.env.REACT_APP_SERVER_SERVICE + "server/apply", {
        method: "POST",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
            serverid: serverid,
        }),
    })

    const data = await response.json()

    if (data.ok) {
        return data;
    } else {
        throw new Error("server could not be created")
    }
}

export async function editApplication(serverid:string, userid: string, confirm: boolean) {

    if(serverid === "" || userid === "") {
        throw new Error("Data missing")
    }
    
    const response = await fetch(process.env.REACT_APP_SERVER_SERVICE + "server/editApplication", {
        method: "POST",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
            serverid: serverid,
            userid: userid,
            confirm: confirm,
        }),
    })

    const data = await response.json()

    if (data.ok) {
        return data;
    } else {
        throw new Error("server could not be created")
    }
}

export async function getServer(serverID: string):Promise<EvjuSpaceServer> {
    let data:EvjuSpaceServer = {_id: "0", name: "Loading...", groups: []}

    const response = await fetch(process.env.REACT_APP_SERVER_SERVICE + "server/server/" + serverID, {
        method: "GET",
        credentials: "include",
        // Pass authentication token as bearer token in header
        headers: {
            "Content-Type": "application/json",
        },
    })
    
    data = await response.json()
    return data
}

export async function getServerFromGroup(groupID: string):Promise<EvjuSpaceServer> {
    let data:EvjuSpaceServer = {_id: "0", name: "Loading...", groups: []}

    const response = await fetch(process.env.REACT_APP_SERVER_SERVICE + "server/serverfromgroup/" + groupID, {
        method: "GET",
        credentials: "include",
        // Pass authentication token as bearer token in header
        headers: {
            "Content-Type": "application/json",
        },
    })
    
    data = await response.json()
    return data
}

export async function newServer(name:string, admin:string) {

    if(name === "" || admin === "") {
        throw new Error("Data missing")
    }
    
    const response = await fetch(process.env.REACT_APP_SERVER_SERVICE + "server/newserver", {
        method: "POST",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
            name: name,
            admin: admin,
        }),
    })

    const data = await response.json()

    if (data.ok) {
        return data;
    } else {
        throw new Error("server could not be created")
    }
}

export async function addMemberToServer(userid:string, serverid:string) {

    if(serverid === "" || userid === "") {
        return "Data Missing"
    }
    
    const response = await fetch(process.env.REACT_APP_SERVER_SERVICE + "server/addmember", {
        method: "POST",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
            serverid: serverid,
            userid: userid,
        }),
    })

    if (response.ok) {
        return true;
    } else {
        return false;
    }
}

export async function removeMemberfromServer(userid:string, serverid:string): Promise<boolean> {

    if(serverid === "" || userid === "") {
        throw new Error("Data missing")
    }
    
    const response = await fetch(process.env.REACT_APP_SERVER_SERVICE + "server/removemember", {
        method: "POST",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
            serverid: serverid,
            userid: userid,
        }),
    })

    if (response.ok) {
        return true;
    } else {
        return false;
    }
}

export async function isUserServerAdmin(serverID: string):Promise<boolean> {
    let result = false

    const response = await fetch(process.env.REACT_APP_SERVER_SERVICE + "server/isAdmin/" + serverID, {
        method: "GET",
        credentials: "include",
        // Pass authentication token as bearer token in header
        headers: {
            "Content-Type": "application/json",
        },
    })
    
    result = await response.json()
    if(result === true) {
        return true
    }

    console.error(result)
    return false
}