import "./../../style.css";
import 'remixicon/fonts/remixicon.css'
import Widget from "./Widget";
import { dateToString } from "../../../Helper/DateFunctions";
import { EvjuSpaceEventSlim, getEvents } from "../../../connectors/EventConnector";
import { useEffect, useState } from "react";
import { EvjuSpaceGroup } from "../../../connectors/GroupConnector";
import { EvjuSpaceUser, getUser } from "../../../connectors/UserConnector";

export interface MembersWidgetParameter {
    group: EvjuSpaceGroup,
    members: Array<EvjuSpaceUser>,
}

function MembersWidget(props : MembersWidgetParameter) {

    
    // const [group, setGroup] = useState<EvjuSpaceGroup>(props.group)
    // const [members, setMembers] = useState<Array<EvjuSpaceUser>>([])
    const group = props.group
    const members = props.members

    // useEffect(() => {
    //     getMembers().then( e=> {
    //         setMembers(e)
    //     })
    // }, [group])

    // async function getMembers() {
    //     let users:Array<EvjuSpaceUser> = []
    //     if(group.members){
    //         for(let member of group.members) {
    //             users.push(await getUser(member.id))
    //         }
    //     }
    //     return users
    // }

    return (
        <Widget headerBox="Gruppenmitglieder" standardHeight={true} data={group.isAdmin ? ("/group/" + group._id + "/members") : ""}>
            {members.map((member: EvjuSpaceUser, i: number) => {
                return <div key={"groupmember"+i}>
                    {member.firstName} {member.lastName}
                </div>
            })}
        </Widget>
    );

}
export default MembersWidget;