import { Link } from "react-router-dom";
import Card from "../Components/Card";

function Settings() {

    return (
        <>
            <Card heading="Einstellungen">
                Hier kannst du:
                <ul>
                    <li>
                        <Link to={"/settings/user"}>dein Benutzerprofil bearbeiten</Link>
                    </li>
                    <li>
                        <Link to={"/settings/applyToServer"}>einem neuen Server beitreten.</Link>
                    </li>
                </ul>
            </Card>
        </>
    );
}
export default Settings;