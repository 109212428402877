import { useContext, useEffect, useState } from "react";
import { EvjuSpaceProtocolItem } from "../../../../../connectors/EventConnector";
import draftToHtml from "draftjs-to-html";
import EvjuSpaceEditor from "../../../../Components/FormElements/EvjuSpaceEditor";
import ProtocolVotingResultEditor from "./ProtocolVotingResultEditor";

function ProtocolSubAgendaItem(props: any) {

    const [protocolItems, setProtocolItems] = useState<EvjuSpaceProtocolItem[]>(props.protocolItems);
    const [editableItem, setEditableItem] = useState<number>(-1)

    useEffect(() => {
        props.updateSubAgendaProtocol(protocolItems)
    }, [protocolItems])

    function activateEdit(i: number) {
        setEditableItem(i);
        props.setParentEditable()
        // props.setEditableAgendaItem(props.agendaItemNumber)
    }

    function addDecision() {
        setProtocolItems(
            [
                ...protocolItems,
                {
                    decision: true,
                    text: "",
                    votingresult: '{"result": [{"option": "Ja", "votes": "0"}, {"option": "Nein", "votes": "0"}, {"option": "Enthaltung", "votes": "0"}]}',
                }
            ]
        )
        activateEdit(protocolItems.length)
    }

    function addText() {
        setProtocolItems(
            [
                ...protocolItems,
                {
                    decision: false,
                    text: ""
                }
            ]
        )
        activateEdit(protocolItems.length)
    }

    function saveRawState(state: any) {
        const nextProtocolItems = protocolItems.map((protocolItem, i) => {
            if(i == editableItem) {
                protocolItem.text = JSON.stringify(state)
                return protocolItem
            } else {
                return protocolItem
            }
        })
        setProtocolItems(nextProtocolItems)
    }

    function getHTML(raw: string) {
        if(raw != "") {
            const markup = draftToHtml(
                JSON.parse(raw)
            )
            return markup;
        }
        return "";
    }

    function saveProtocolItemVotingResult(result: string) {
        const nextProtocolItems = protocolItems.map((protocolItem, i) => {
            if(i == editableItem) {
                protocolItem.votingresult = result
                return protocolItem
            } else {
                return protocolItem
            }
        })
        setProtocolItems(nextProtocolItems)
    }
console.log(props.parentEditable)
console.log(props.agendaItemNumber)
    return <div>
        {(protocolItems != null) ? protocolItems?.map((protocolItem: EvjuSpaceProtocolItem, i:number) => {
            return (
                <div key={"protocolItemContainer"+i}>
                    {(!protocolItem.decision && (editableItem != i || props.editableAgendaItem != props.agendaItemNumber)) &&
                        <div key={"protocolItem"+i} className="protocolitem" onClick={() => {activateEdit(i)}}>
                            <div className="protocolitemtext" dangerouslySetInnerHTML={{__html: getHTML(protocolItem.text)}} />
                        </div>
                    }
                    {(protocolItem.decision && (editableItem != i || props.editableAgendaItem != props.agendaItemNumber)) &&
                        <div key={"protocolItem"+i} className="protocolitem" onClick={() => {activateEdit(i)}}>
                            <div className="protocolitemtext" dangerouslySetInnerHTML={{__html: getHTML(protocolItem.text)}} />
                            <div>
                                <b>Ergebnis:</b><br />

                                {protocolItem.votingresult && JSON.parse(protocolItem.votingresult).result.map((option: any, j:number) => {
                                    return (
                                        <div key={"protocolItemResult"+i+"-"+j}>{option.option}: {option.votes}<br /></div>
                                    );
                                })}
                            </div>
                        </div>
                    }
                    {(editableItem == i && props.editableAgendaItem == props.agendaItemNumber && props.parentEditable) &&
                        <EvjuSpaceEditor key={"protocolItemEditor"+i} rawState={protocolItem.text} onChangeExportRawState={saveRawState} />
                    }
                    {(protocolItem.decision && editableItem == i && props.editableAgendaItem == props.agendaItemNumber && props.parentEditable) &&
                        <div key={"protocolItemResultEditorDiv"+i} className="protocolitemresulteditor">
                            <b>Ergebnis:</b><br /><br />
                            <ProtocolVotingResultEditor key={"protocolItemResultEditor"+i} votingResult={protocolItem.votingresult} onChange={saveProtocolItemVotingResult} />
                        </div>
                    }
                </div>
            );
        }) : ""}
        <a className="protocolitemlink" onClick={addText}>Text hinzufügen</a> <a className="protocolitemlink" onClick={addDecision}>Abstimmung hinzufügen</a>
    </div>;
}

export default ProtocolSubAgendaItem;