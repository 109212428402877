import draftToHtml from "draftjs-to-html";
import Widget from "./Widget";

function DescriptionWidget(props : any) {

    return (
        <Widget headerBox={props.headline} standardHeight={true}>
            {props.description? <div dangerouslySetInnerHTML={{__html: draftToHtml(JSON.parse(props.description))}} ></div> : "Die Gruppenbeschreibung kannst du in den Einstellungen dieser Gruppe bearbeiten."}
        </Widget>
    );

}
export default DescriptionWidget;