import { useState } from "react";
import { Link, useLoaderData } from "react-router-dom";
import EvjuSpaceInput from "../Layout/Components/FormElements/EvjuSpaceInput";
import EvjuSpaceButton from "../Layout/Components/FormElements/EvjuSpaceButton";
import { isResetValid, setNewPassword } from "../connectors/UserConnector";

function SetNewPassword() {

    const [ resetid, userid, resetValid ] : any = useLoaderData();

    const [error, setError] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [passwordConfirmation, setPasswordConfirmation] = useState<string>("");
    const [sent, setSent] = useState<boolean>(false);

    function formSubmitHandler(e : any) {
        e.preventDefault()
        setError("")

        if(password == "") {
            setError("Bitte gib ein neues Passwort ein ein.")
        } else if(password != passwordConfirmation) {
            setError("Die Passwörter sind nicht identisch.")
        } else {
            setNewPassword(resetid, userid, password)
            setSent(true)
        }
    }

    return (
        <>
            {(!sent && resetValid) && <div className="login-container">
                <h1>Neues Passwort</h1>
                {error && (<div>ERROR:<br /> {error}</div>)}
                <form onSubmit={formSubmitHandler}>
                    <EvjuSpaceInput type={"password"} onChange={setPassword} value={password} label="Neues Passwort" />
                    <EvjuSpaceInput type="password" onChange={setPasswordConfirmation} value={passwordConfirmation} label="Passwort bestätigen" />
                    <EvjuSpaceButton class="login-button" text="Passwort zurücksetzen" onClick={() => {}} />

                </form>

                <div id="login-links">
                    <Link className="forgot-password-link login-link" to={"/login"}>Zurück zum Login</Link>
                </div>
            </div>}
            {(sent && resetValid) && <div className="login-container">
                <h1>Neues Passwort</h1>
                <p>Dein Passwort wurde erfolgreich geändert.</p>
                <p>Du kannst dich nun mit deinem neuen Passwort wieder einloggen.</p>
                <div id="login-links">
                    <Link className="forgot-password-link login-link" to={"/login"}>Zurück zum Login</Link>
                </div>
            </div>}

            {(!resetValid) && <div className="login-container">
                <h1>Link ungültig</h1>
                <p>Dieser Link zum zurücksetzen des Passworts ist nicht gültig.</p>
                <p>Möglicherweise ist er abgelaufen. Lass dir gerne einen neuen Link zuschicken!</p>

                <div id="login-links">
                    <Link className="forgot-password-link login-link" to={"/forgotPassword/"}>Passwort vergessen</Link>
                    <Link className="register-link login-link" to={"/login"}>Zurück zum Login</Link>
                </div>
            </div>}
        </>
    );
}

export default SetNewPassword;

export async function setNewPasswordLoader({ params } : any) {

    const resetid = params.resetid
    const userid = params.userid

    const resetValid = await isResetValid(resetid, userid)

    console.log(resetValid)

    return [ resetid, userid, resetValid ];
}