import { useEffect, useState } from "react";
import 'react-select-search/style.css'
import { EvjuSpaceUser, getUser, getUserList } from "../../../connectors/UserConnector";
import EvjuSpaceInput from "./EvjuSpaceInput";

export interface EvjuSpaceUserSelectorParameter {
    label?: string,
    onChange?: any,
    onSave?: any,
    set?: Array<string>,
    initialValue?: Array<string>,
}

function EvjuSpaceUserSelector(props: EvjuSpaceUserSelectorParameter) {

    const [inputValue, setInputValue] = useState<string>("")
    const [validatedValue, setValidatetValue] = useState<Array<string>>()
    const [displaySearchResult, setDisplaySearchResult] = useState<boolean>(false)
    const [userSet, setUserSet] = useState<Array<{uid: string, name: string}>>()

    useEffect(() => {
        const fetchData = async () => {
            if(props.initialValue) {
                let newInputValue: string = ""
                for(const uid of props.initialValue) {
                    const user = await getUser(uid)
                    newInputValue += user.firstName + " " + user.lastName + ", "
                }
                setValidatetValue(props.initialValue)
                setInputValue(newInputValue)
            }
        }
        fetchData()
    }, [])

    useEffect(() => {
        if(props.set) {
            const fetchData = () => {
                let newUserSet: Array<{uid: string, name: string}> = []
                if(props.set) {
                    props.set.forEach(async opt => {
                        const optUser = await getUser(opt)
                        let name = ""
                        if(optUser.firstName && optUser.lastName) {
                            name = optUser.firstName + " " + optUser.lastName
                        }
                        newUserSet.push({uid: opt, name: name})
                    });
                    setUserSet(newUserSet)
                } else {

                }
            }
            fetchData()
        } else {
            const fetchData = async () => {
                const userList: Array<EvjuSpaceUser> = await getUserList()
                let newUserSet: Array<{uid: string, name: string}> = []
                for(const user of userList) {
                    newUserSet.push({uid: user._id, name: user.firstName + " " + user.lastName})
                }
                setUserSet(newUserSet)
            }
            fetchData()
        }
    }, [props.set])

    function selectUser(user: {uid: string, name: string}) {
        setInputValue(user.name)
        setValidatetValue([user.uid])
        if(props.onChange) {
            props.onChange([user.uid])
        }
    }

    function onSave() {
        props.onSave()
        setInputValue("")
        setValidatetValue([])
        props.onChange([])
    }

    return (
        <div className="evjuspaceinput-container" onFocus={() => {setDisplaySearchResult(true)}} onBlur={() => {setDisplaySearchResult(false)}}>
            <EvjuSpaceInput value={inputValue} onChange={setInputValue} label={props.label} onSave={props.onSave? onSave : undefined} />
            {displaySearchResult ? (<div className="evjuspaceuserselector-searchResults">
                {userSet?.map((user, i) => {
                    return (
                        <div className="evjuspaceuserselector-searchResult" onMouseDown={() => {selectUser(user)}}>
                            {user.name}
                        </div>
                    )
                })}
            </div>) : ""}
        </div>
    );
}
export default EvjuSpaceUserSelector;