
import React from "react"
import { PacmanLoader } from "react-spinners"

const Loader = () => {
  return (
    <div className="loader">
      <PacmanLoader color="#ffffff" />
    </div>
  )
}

export default Loader
