import { useEffect, useState } from "react";
import 'react-select-search/style.css'
import { EvjuSpaceUser, getUser, getUserList } from "../../../connectors/UserConnector";
import EvjuSpaceInput from "./EvjuSpaceInput";
import EvjuSpaceOverlay from "../Overlay";

export interface EvjuSpaceUserSelectorParameter {
    label?: string,
    onChange?: any,
    onSave?: any,
    set?: Array<string>,
    value?: Array<string>,
    multiselect?: boolean,
}

function EvjuSpaceUserSelector(props: EvjuSpaceUserSelectorParameter) {

    const [inputValue, setInputValue] = useState<string>("")
    const [isOverlayOpen, setIsOverlayOpen] = useState<boolean>(false)
    const [searchValue, setSearchValue] = useState<string>("")
    const [userSet, setUserSet] = useState<Array<{uid: string, name: string}>>()
    const [searchedSet, setSearchedSet] = useState<Array<{uid: string, name: string}>>()
    const [selectedUsers, setSelectedUsers] = useState<Array<string>>(props.value? props.value : [])

    useEffect(()=>{
        setInputValue(getSelectedNames(selectedUsers))
    }, [selectedUsers, userSet])

    useEffect(() => {
        setSearchedSet(userSet)
    }, [userSet])

    useEffect(() => {
        setSearchedSet(userSet?.filter(element => element.name.toLowerCase().includes(searchValue.toLowerCase())))
    }, [searchValue])

    useEffect(() => {
        if(props.set && !userSet) {
            const fetchData = () => {
                let newUserSet: Array<{uid: string, name: string}> = []
                if(props.set) {
                    props.set.forEach(async opt => {
                        const optUser = await getUser(opt)
                        let name = ""
                        if(optUser.firstName && optUser.lastName) {
                            name = optUser.firstName + " " + optUser.lastName
                        }
                        newUserSet.push({uid: opt, name: name})
                    });
                    setUserSet(newUserSet)
                }
            }
            fetchData()
        } else {
            const fetchData = async () => {
                const userList: Array<EvjuSpaceUser> = await getUserList()
                let newUserSet: Array<{uid: string, name: string}> = []
                for(const user of userList) {
                    newUserSet.push({uid: user._id, name: user.firstName + " " + user.lastName})
                }
                setUserSet(newUserSet)
            }
            fetchData()
        }
    }, [props.set])
    
    function onFocus(e: any) {
        setIsOverlayOpen(true)
    }

    function isSelected(uid: string): boolean {
        return selectedUsers.includes(uid)
    }

    function toggleSelection(uid: string) {
        let newSelectedUsers = [];
        if(selectedUsers.includes(uid)) {
            newSelectedUsers = selectedUsers.filter(element => element !== uid)
        } else {
            if(props.multiselect) {
                newSelectedUsers = [...selectedUsers, uid]
            } else {
                newSelectedUsers = [uid]
            }
        }

        setSelectedUsers(newSelectedUsers)
        props.onChange(newSelectedUsers)
    }

    function getSelectedNames(newSelectedUsers: Array<string>) : string {
        let result = ""

        if(userSet) {
            for(const user of userSet) {
                if(newSelectedUsers.includes(user.uid)) {
                    if(result !== "") {
                        result += "; "
                    }
                    result += user.name
                }
            }
        }
        return result
    }

    return (<>
        <EvjuSpaceInput value={inputValue} onChange={setInputValue} label={props.label} onFocus={onFocus} />

        <EvjuSpaceOverlay isOpen={isOverlayOpen} onClose={() => {setIsOverlayOpen(false)}}>
            <h2>Benutzer auswählen</h2>
            <EvjuSpaceInput value={searchValue} onChange={setSearchValue} label="Suchen" />
            <table>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Auswahl</th>
                    </tr>
                </thead>
                <tbody>
                    {searchedSet?.map((user, i) => {
                        return (
                            <>
                                <tr>
                                    <td>{user.name}</td>
                                    <td>{isSelected(user.uid) ?
                                        (<a className="toggle-button ri-toggle-fill" title="Anwesenheit einschalten" onClick={() => {toggleSelection(user.uid)}}></a>) :
                                        (<a className="toggle-button ri-toggle-line" title="Anwesenheit ausschalten" onClick={() => {toggleSelection(user.uid)}}></a>)}
                                    </td>
                                </tr>
                            </>
                        )
                    })}
                </tbody>
            </table>
        </EvjuSpaceOverlay>
    </>);
}
export default EvjuSpaceUserSelector;