import { useRouteError } from "react-router-dom";
import "../index.css";

function Error() {
    const error:any = useRouteError();
    console.error(error);
  
    return (
      <div className="error-page">
        <h1>Oops!</h1>
        <p>Sorry, an unexpected error has occurred.</p>
        <p>
          <i>{error.statusText || error.message}</i>
        </p>
        <p>
          <a href="/">Zurück zur Startseite!</a>
        </p>
      </div>
    );
}

export default Error;