import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { EvjuSpaceServer, applyToServer, getPossibleServerApplicationList } from "../../connectors/ServerConnector";

function ApplyToServer() {

    const [possibleApplications, setPossibleApplications] = useState<any>([])

    useEffect(() => {
        const fetchData = async () => {
            setPossibleApplications(await getPossibleServerApplicationList())
        }

        fetchData()
    }, [])

    async function apply(serverid: string) {
        await applyToServer(serverid)
        setPossibleApplications(await getPossibleServerApplicationList())
    }

    return (
        <>
            <table className="table">
                <thead className="table-head">
                    <tr>
                        <th className="table-cell">Servername</th>
                        <th className="table-cell">Antrag stellen</th>
                    </tr>
                </thead>
                <tbody>
                    {possibleApplications && possibleApplications?.map((possibleApplication: EvjuSpaceServer, i: number) => {
                        const row = i % 2 == 0 ? "table-row-odd" : "table-row-even"
                        return <>
                                <tr>
                                    <td className={"table-cell " + row}>{possibleApplication.name}</td>
                                    <td className={"table-cell " + row}>{possibleApplication.applicants && possibleApplication.applicants.length == 0 ?
                                        <Link to="#" className="ri-add-circle-line" onClick={() => {apply(possibleApplication._id)}}></Link> :
                                        "In Bearbeitung"}</td>
                                </tr>
                            </>
                    })}
                </tbody>
            </table>
        </>
    );
}
export default ApplyToServer;



// export async function applyToServerLoader({ params } : any) {

//     const possibleApplicationList = await getPossibleServerApplicationList()
  
//     return { possibleApplicationList };
//   }