import "./../../style.css";
import 'remixicon/fonts/remixicon.css'
import Widget from "./Widget";
import { dateToString } from "../../../Helper/DateFunctions";
import { EvjuSpaceEvent, EvjuSpaceEventSlim, getEvents } from "../../../connectors/EventConnector";
import { useEffect, useState } from "react";
import { EvjuSpaceGroup } from "../../../connectors/GroupConnector";

export interface GroupEventWidgetParameter {
    group: EvjuSpaceGroup,
    events: Array<EvjuSpaceEvent>
}

function GroupEventWidget(props : GroupEventWidgetParameter) {

    
    // const [group, setGroup] = useState<EvjuSpaceGroup>(props.group)
    const group = props.group
    // const [events, setEvents] = useState<EvjuSpaceEventSlim[]>([{_id: "123234252351235414", name: "Loading", starttime: new Date()}])
    const events = props.events

    // useEffect(() => {
    //     getEvents(group._id).then( e=> {
    //         setEvents(e)
    //     })
    // }, [])

    return (
        <Widget headerBox="Termine" add={group.isAdmin ? ("/group/" + group._id + "/createEvent") : ""} standardHeight={true}>
            { Array.isArray(events) ? events.map((event:any, j:any) => {
                        return (
                            <div key={"event" + j}>
                                <a href={"/group/" + group._id + "/event/" + event._id}>
                                    { event.name + " (" + dateToString(event.starttime) + ")" } 
                                </a>
                            </div>
                        );
                    }
                ) : ""
            }
        </Widget>
    );

}
export default GroupEventWidget;