export interface EvJuSpaceSetting {
    _id?: string,
    name: string,
    value: string,
}

export async function setSetting(setting: EvJuSpaceSetting) {

}

export async function getSetting(name: string) : Promise<string> {
    if(name == "registrationEnabled") {
        return 'true'
    } else {
        throw new Error("ERROR 217846")
    }
}