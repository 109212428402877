

export interface EvjuSpaceUser {
    _id: string,
    username?: string,
    firstName?: string,
    lastName?: string,
    emailConfirmed?: boolean,
    termsOfUseAgreed?: number,
    dataProtectionConditionAgreed?: number,
    street?: string,
    houseNumber?: string,
    zipCode?: string,
    city?: string,
    smartphone?: string,
    dateOfBirth?: Date,
    driversLicense?: string,
    emergencyContact?: string,
    eatingHabits?: string,
    tshirtSize?: string,
    specialties?: string,
}
export interface Certification {
    _id?: string,
    userid: string,
    title: string,
    issueDate: Date,
    expirationDate?: Date,
    validated?: boolean,
    validationDate?: Date,
    archived?: boolean,
}

export async function getUserDetails() {
    const response = await fetch(process.env.REACT_APP_USER_SERVICE + "users/me", {
        method: "GET",
        credentials: "include",
        // Pass authentication token as bearer token in header
        headers: {
          "Content-Type": "application/json",
        },
    })

    if (response.ok) {
        const data = await response.json()
        return data
    } else {
        if (response.status === 401) {
        // Edge case: when the token has expired.
        // This could happen if the refreshToken calls have failed due to network error or
        // User has had the tab open from previous day and tries to click on the Fetch button
        window.location.reload()
        } else {
        return null
        }
    }
}

export async function getUserList():Promise<Array<EvjuSpaceUser>> {
    const response = await fetch(process.env.REACT_APP_USER_SERVICE + "users/userList", {
        method: "GET",
        credentials: "include",
        // Pass authentication token as bearer token in header
        headers: {
            "Content-Type": "application/json",
        },
    })
    
    const data = await response.json()
    return data.users
}

export async function getUser(userid: string) : Promise<EvjuSpaceUser> {
    //return {_id: userid, firstname: 'test', lastname: 'user'}

    const response = await fetch(process.env.REACT_APP_USER_SERVICE + "users/getuser/" + userid, {
        method: "GET",
        credentials: "include",
        // Pass authentication token as bearer token in header
        headers: {
            "Content-Type": "application/json",
        },
    })
    
    const data = await response.json()
    return data
}

export async function getUserFromRefreshToken(refreshToken: string): Promise<EvjuSpaceUser> {
    // console.log(refreshToken)
    const response = await fetch(process.env.REACT_APP_USER_SERVICE + "users/userFromToken/", {
        method: "GET",
        credentials: "include",
        // Pass authentication token as bearer token in header
        headers: {
            "Content-Type": "application/json",
            Authorization: refreshToken,
        },
    })
    
    const data = await response.json()
    return data
}

export async function updateUser(updatedUser: EvjuSpaceUser) {
    const response = await fetch(process.env.REACT_APP_USER_SERVICE + "users/update", {
        method: "POST",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
            user: updatedUser,
        }),
    })

    const data = await response.json()

    if (data.ok) {
        return data;
    } else {
        throw new Error("Request could not be sent")
    }
}

export async function requestPasswordReset(mail: string) {
    
    const response = await fetch(process.env.REACT_APP_USER_SERVICE + "password/requestReset", {
        method: "POST",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
            mail: mail,
        }),
    })

    const data = await response.json()

    if (data.ok) {
        return data;
    } else {
        throw new Error("Request could not be sent")
    }
}

export async function isResetValid(resetid: string, userid: string): Promise<boolean> {

    const response = await fetch(process.env.REACT_APP_USER_SERVICE + "password/isResetValid/" + resetid + "/" + userid, {
        method: "GET",
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
        },
    })
    
    const data = await response.json()
    return data
}

export async function setNewPassword(resetid: string, userid: string, password: string) {
    
    const response = await fetch(process.env.REACT_APP_USER_SERVICE + "password/setNewPassword", {
        method: "POST",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
            resetid: resetid,
            userid: userid,
            password: password,
        }),
    })

    const data = await response.json()

    if (data.ok) {
        return data;
    } else {
        throw new Error("Request could not be sent")
    }
}

export async function confirmEmail(userid: string, username: string) : Promise<EvjuSpaceUser> {

    const response = await fetch(process.env.REACT_APP_USER_SERVICE + "users/confirmEmail/" + userid + "/" + username, {
        method: "GET",
        // Pass authentication token as bearer token in header
        headers: {
            "Content-Type": "application/json",
        },
    })
    
    const data = await response.json()
    return data
}

export async function addCertificationToUser(certification: Certification) {
    
    const response = await fetch(process.env.REACT_APP_USER_SERVICE + "users/createCertification", {
        method: "POST",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
            certification: certification
        }),
    })

    const data = await response.json()

    if (data.ok) {
        return data;
    } else {
        throw new Error("Request could not be sent")
    }
}

export async function getUsersCertifications(userid: string) : Promise<Array<Certification>> {

    const response = await fetch(process.env.REACT_APP_USER_SERVICE + "users/getUsersCertifications/" + userid, {
        method: "GET",
        credentials: "include",
        // Pass authentication token as bearer token in header
        headers: {
            "Content-Type": "application/json",
        },
    })
    
    const data = await response.json()
    return data
}

export async function archiveCertification(certificationId: string) {
    
    const response = await fetch(process.env.REACT_APP_USER_SERVICE + "users/archiveCertification", {
        method: "POST",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
            certificationId: certificationId
        }),
    })

    const data = await response.json()

    if (data.ok) {
        return data;
    } else {
        throw new Error("Request could not be sent")
    }
}