import "./../../style.css";
import 'remixicon/fonts/remixicon.css'

function EvjuSpaceCheckbox(props : any) {

    function change(e:any) {
        if(props.onChange) {
            props.onChange(e.target.checked)
        }
    }

    return (
        <div className="evjuspaceinput-container">
            <input
                className="evjuspaceinput-checkbox"
                type="checkbox"
                checked={ props.value && props.value == true ? true : false}
                disabled={ props.disabled && props.disabled == true ? true : false}
                onChange={ change }
            />
            <div className="evjuspaceinput-checkboxlabel">
                { props.label ? props.label : "" }
            </div>
        </div>);

}
export default EvjuSpaceCheckbox;