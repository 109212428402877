import "./../style.css";

interface EvjuSpaceOverlayParameter {
    isOpen: boolean,
    onClose: any,
    children: any,
}

function EvjuSpaceOverlay(props: EvjuSpaceOverlayParameter) {

    return (
        <>
            {props.isOpen ? (
                <div className="overlay">
                    <div className="overlay_background" onClick={props.onClose}>
                    </div>
                    <div className="overlay_container">
                        <div className="overlay_controls">
                            <button className="overlay_close" type="button" onClick={props.onClose}></button>
                        </div>
                        {props.children}
                    </div>
                </div>
            ) : null}
        </>
    )
}

export default EvjuSpaceOverlay;