import { useLoaderData, useOutletContext } from "react-router-dom";
import { getServer } from "../../connectors/ServerConnector";
import Card from "../Components/Card";
import MemberList from "../Components/MemberList";
import NewGroup from "./ServerSettings/NewGroup";
import { useEffect } from "react";

function ServerSettings() {

    const server : any = useLoaderData();

    useEffect(() => {
        if(!server.isAdmin) {
            window.location.replace("/server/" + server._id);
        }
    }, [])

    return (
        <>
            <Card heading={server? server.name : "Loading..."} headerBox="Servereinstellungen" back={server._id ? "/server/" + server._id : "#"} >
                <h3>Mitgliederverwaltung</h3>
                <MemberList server={server} allowAdding={true} />
                <hr />
                <NewGroup server={server} />
            </Card>
        </>
    );
}
export default ServerSettings;

export async function serverSettingLoader({ params } : any) {

    const server = getServer(params.id)
    return server;
}