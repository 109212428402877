import { useState } from "react";
import "./../style.css";
import 'remixicon/fonts/remixicon.css'

export interface indexCardProps {
    headerBox: string,
    initialyOpen?: boolean,
    children: any,
}

function IndexCard(props : indexCardProps) {

    const [isOpen, setIsOpen] = useState<boolean>(props.initialyOpen? props.initialyOpen : false)

    return (
        
            
        <div className={isOpen? "card-container" : "card-container-closed"}>
            <div className={isOpen? "card-header" : "card-header-closed"} onClick={() => {setIsOpen(!isOpen)}}>
                <div className="card-title">
                    {props.headerBox}
                </div>
                <div className="card-settings">
                    {isOpen? <span className="ri-arrow-up-s-line"></span> : <span className="ri-arrow-down-s-line"></span>}
                </div>
            </div>
            {isOpen?
                <>
                    <div className="card-content">
                        {props.children}
                    </div>
                </>
                : ""}
        </div>
    );

}
export default IndexCard;