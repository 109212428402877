import { dateToFilenameFormat } from "../Helper/DateFunctions"
import { EvjuSpaceEvent } from "./EventConnector"

export async function getProtocolPDF(event: EvjuSpaceEvent) {
    // let data:EvjuSpaceEventSlim[] = [{_id: "0", name: "Loading...", starttime: new Date()}]

    const response = await fetch(process.env.REACT_APP_FILE_SERVICE + "pdf/protocolPDF/" + event._id, {
        method: "GET",
        credentials: "include",
        // Pass authentication token as bearer token in header
        headers: {
            "Content-Type": "application/json",
        },
    }).then((res) => {
        res.blob().then((blob) => {
            const fileURL = window.URL.createObjectURL(blob)

            let alink = document.createElement("a")
            alink.href = fileURL
            alink.download = dateToFilenameFormat(event.starttime) + "_Protokoll.pdf"
            alink.click()
        })
    })
    
    // data = await response.json()
    
}

export async function getInvitationPDF(event: EvjuSpaceEvent) {
    const response = await fetch(process.env.REACT_APP_FILE_SERVICE + "pdf/invitationPDF/" + event._id, {
        method: "GET",
        credentials: "include",
        // Pass authentication token as bearer token in header
        headers: {
            "Content-Type": "application/json",
        },
    }).then((res) => {
        res.blob().then((blob) => {
            const fileURL = window.URL.createObjectURL(blob)

            let alink = document.createElement("a")
            alink.href = fileURL
            alink.download = dateToFilenameFormat(event.starttime) + "_Einladung.pdf"
            alink.click()
        })
    })
    
}