import "./../../../../style.css";
import 'remixicon/fonts/remixicon.css'
import Widget from "../../../../Components/Widgets/Widget";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../../../context/UserContext";
import EvjuSpaceEditor from "../../../../Components/FormElements/EvjuSpaceEditor";
import { EvjuSpaceProtocolItem, EvjuSpaceSubAgendaItem, } from "../../../../../connectors/EventConnector";
import draftToHtml from "draftjs-to-html";
import ProtocolVotingResultEditor from "./ProtocolVotingResultEditor";
import ProtocolSubAgendaItem from "./ProtocolSubAgendaItem";

function ProtocolItemWidget(props : any) {

    const [userContext, setUserContext] = useContext<any>(UserContext);
    const agendaItem: any = props.agendaItem
    const [protocolItems, setProtocolItems] = useState<EvjuSpaceProtocolItem[]>(props.agendaItem.protocol);
    const [editableItem, setEditableItem] = useState<number>(-1)

    useEffect(() => {
        props.updateProtocol(props.agendaItemNumber, protocolItems)
    }, [protocolItems])

    function addDecision() {
        setProtocolItems(
            [
                ...protocolItems,
                {
                    decision: true,
                    text: "",
                    votingresult: '{"result": [{"option": "Ja", "votes": "0"}, {"option": "Nein", "votes": "0"}, {"option": "Enthaltung", "votes": "0"}]}',
                }
            ]
        )
        activateEdit(protocolItems.length)
    }

    function addText() {
        setProtocolItems(
            [
                ...protocolItems,
                {
                    decision: false,
                    text: ""
                }
            ]
        )
        activateEdit(protocolItems.length)
    }

    function saveRawState(state: any) {
        const nextProtocolItems = protocolItems.map((protocolItem, i) => {
            if(i == editableItem) {
                protocolItem.text = JSON.stringify(state)
                return protocolItem
            } else {
                return protocolItem
            }
        })
        setProtocolItems(nextProtocolItems)
    }

    function saveProtocolItemVotingResult(result: string) {
        const nextProtocolItems = protocolItems.map((protocolItem, i) => {
            if(i == editableItem) {
                protocolItem.votingresult = result
                return protocolItem
            } else {
                return protocolItem
            }
        })
        setProtocolItems(nextProtocolItems)
    }

    function getHTML(raw: string) {
        if(raw != "") {
            const markup = draftToHtml(
                JSON.parse(raw)
            )
            return markup;
        }
        return "";
    }

    function activateEdit(i: number) {
        setEditableItem(i);
        props.setEditableAgendaItem(props.agendaItemNumber)
    }

    return (
        <Widget headerBox={props.agendaItemNumber + 1 + ". " + agendaItem.name} >

            {((!agendaItem.subAgenda || agendaItem.subAgenda.length == 0) && protocolItems != null) ? protocolItems?.map((protocolItem: EvjuSpaceProtocolItem, i:number) => {
                return (
                    <div key={"protocolItemContainer"+i}>
                        {(!protocolItem.decision && (editableItem != i || props.editableAgendaItem != props.agendaItemNumber)) &&
                            <div key={"protocolItem"+i} className="protocolitem" onClick={() => {activateEdit(i)}}>
                                <div className="protocolitemtext" dangerouslySetInnerHTML={{__html: getHTML(protocolItem.text)}} />
                            </div>
                        }
                        {(protocolItem.decision && (editableItem != i || props.editableAgendaItem != props.agendaItemNumber)) &&
                            <div key={"protocolItem"+i} className="protocolitem" onClick={() => {activateEdit(i)}}>
                                <div className="protocolitemtext" dangerouslySetInnerHTML={{__html: getHTML(protocolItem.text)}} />
                                <div>
                                    <b>Ergebnis:</b><br />

                                    {protocolItem.votingresult && JSON.parse(protocolItem.votingresult).result.map((option: any, j:number) => {
                                        return (
                                            <div key={"protocolItemResult"+i+"-"+j}>{option.option}: {option.votes}<br /></div>
                                        );
                                    })}
                                </div>
                            </div>
                        }
                        {(editableItem == i && props.editableAgendaItem == props.agendaItemNumber) &&
                            <EvjuSpaceEditor key={"protocolItemEditor"+i} rawState={protocolItem.text} onChangeExportRawState={saveRawState} />
                        }
                        {(protocolItem.decision && editableItem == i && props.editableAgendaItem == props.agendaItemNumber) &&
                            <div key={"protocolItemResultEditorDiv"+i} className="protocolitemresulteditor">
                                <b>Ergebnis:</b><br /><br />
                                <ProtocolVotingResultEditor key={"protocolItemResultEditor"+i} votingResult={protocolItem.votingresult} onChange={saveProtocolItemVotingResult} />
                            </div>
                        }
                    </div>
                );
            }) : ""}
            {(!agendaItem.subAgenda || agendaItem.subAgenda.length == 0) &&
                <>
                    <div id={"editor-" + agendaItem._id}></div>

                    <a className="protocolitemlink" onClick={addText}>Text hinzufügen</a> <a className="protocolitemlink" onClick={addDecision}>Abstimmung hinzufügen</a>
                </>
            }


            {((agendaItem.subAgenda && agendaItem.subAgenda.length != 0) && protocolItems != null) ? agendaItem.subAgenda?.map((subAgendaItem: EvjuSpaceSubAgendaItem, i:number) => {
                return <div key={"subAgendaItem"+i} className="subAgendaProtocol">
                    <b>{(props.agendaItemNumber+1) + "." + (i+1) + ". " + subAgendaItem.name}</b>
                    <br />
                    <ProtocolSubAgendaItem protocolItems={subAgendaItem.protocol} updateSubAgendaProtocol={(p: EvjuSpaceProtocolItem[]) => {props.updateSubAgendaProtocol(props.agendaItemNumber, i, p)}} parentEditable={(props.editableAgendaItem == props.agendaItemNumber)} setParentEditable={() => {props.setEditableAgendaItem(props.agendaItemNumber)}} />
                </div>
            }) : ""}

        </Widget>
    );

}
export default ProtocolItemWidget;