import "./../../style.css";
import 'remixicon/fonts/remixicon.css'
import "react-datepicker/dist/react-datepicker.css";
import ReactDatePicker from "react-datepicker";

export interface dateTimeProps {
    onChange: any,
    label: string,
    value?: Date|undefined,
    dateOnly?: boolean,
}

function EvjuSpaceDateTime(props : dateTimeProps) {

    function change(date: Date) {
        if(props.onChange) {
            props.onChange(date)
        }
    }

    let dateFormat = "dd.MM.yyyy HH:mm"
    if(props.dateOnly) {
        dateFormat = "dd.MM.yyyy"
    }

    return (
        <div className="evjuspaceinput-container">
            <div className="evjuspaceinput-label">
                { props.label ? props.label : "" }
            </div>
            
            {/* Examples for Usage: https://reactdatepicker.com/ */}
            <ReactDatePicker
                className="evjuspaceinput evjuspaceinput-input"
                dateFormat={dateFormat}
                selected={props.value ? props.value : new Date()}
                onChange={ change }
                timeInputLabel="Zeit:"
                showTimeInput
            />
        </div>
    );
}
export default EvjuSpaceDateTime;