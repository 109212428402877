import "./../../style.css";
import 'remixicon/fonts/remixicon.css'

export interface WidgetParameter {
    standardHeight?: boolean,
    headerBox: string,
    add?: string,
    edit?: string,
    data?: string,
    children: any,
}

function Widget(props : any) {

    function getHeight(): string {
        if(props.standardHeight) {
            return " widget-standard-height"
        }
        return ""
    }

    return (
        <div className="widget-container">
            <div className="widget-header">
                <div className="widget-title">
                    {props.headerBox}
                </div>
                <div className="card-settings">
                    {props.add ? <a className="widget-icon ri-add-line" title="Hinzufügen" href={props.add}></a> : ""}
                    {props.edit ? <a className="widget-icon ri-edit-2-line" title="Bearbeiten" href={props.edit}></a> : ""}
                    {props.data ? <a className="widget-icon ri-table-line" title="Datenbank" href={props.data}></a> : ""}
                </div>
            </div>
            <div className={"widget-content" + getHeight()}>
                {props.children}
            </div>
        </div>
    );

}
export default Widget;