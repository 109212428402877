import { useLoaderData } from "react-router-dom";
import Card from "../../Components/Card";
import { addMemberToGroup, getGroup, updateGroup } from "../../../connectors/GroupConnector";
import EvjuSpaceInput from "../../Components/FormElements/EvjuSpaceInput";
import { useEffect, useState } from "react";
import EvjuSpaceEditor from "../../Components/FormElements/EvjuSpaceEditor";
import EvjuSpaceMemberList from "../../Components/MemberList";
import { getServerFromGroup } from "../../../connectors/ServerConnector";

function GroupSettings() {

    const [ group, server ] : any = useLoaderData();

    const [userToAdd, setUserToAdd] = useState<string>();
    const [formerror, setFormerror] = useState<string>("");
    const [groupName, setGroupName] = useState<string>(group.name);
    const [groupDescription, setGroupDescription] = useState<string>(group.description);
    const [protocolHeadline, setProtocolHeadline] = useState<string>(group.protocolHeadline);
    const [invitatoionHeadline, setInvitationHeadline] = useState<string>(group.invitationHeadline);
    const [invitatoionFirstText, setInvitatoionFirstText] = useState<string>(group.invitationFirstText);
    const [invitatoionSecondText, setInvitatoionSecondText] = useState<string>(group.invitationSecondText);

    useEffect(() => {
        if(!group.isAdmin) {
            window.location.replace("/group/" + group._id);
        }
    }, [])

    useEffect(() => {
        group.name = groupName;
        group.protocolHeadline = protocolHeadline;
        group.description = groupDescription;
        group.invitationHeadline = invitatoionHeadline;
        group.invitationFirstText = invitatoionFirstText;
        group.invitationSecondText = invitatoionSecondText;

        updateGroup(group);

    }, [protocolHeadline, groupName, groupDescription, invitatoionHeadline, invitatoionFirstText, invitatoionSecondText])

    async function btnHandler() {
        if(userToAdd === "") {
            setFormerror("Es wurde kein User ausgewählt.")
        } else {
            if(userToAdd) {
                const response = await addMemberToGroup(userToAdd, group._id)
                // if(response.ok) {
                //     TOAST NACHRICHT !?
                // }
            }
        }
    }

    return (
        <>
            <Card heading={group? group.name : "Loading..."} headerBox="Gruppeneinstellungen" back={group._id ? "/group/" + group._id : "#"} >
                <h3>Mitgliederverwaltung</h3>
                <EvjuSpaceMemberList server={server} group={group} allowAdding={true} />
                <h3>Allgemeines</h3>
                <EvjuSpaceInput label="Gruppenname" value={groupName} onChange={setGroupName} />
                <p>Gruppenbeschreibung</p>
                <EvjuSpaceEditor rawState={groupDescription} onChangeExportRawState={(state: any) => {setGroupDescription(JSON.stringify(state))}} />
                <h3>Protokolle</h3>
                <EvjuSpaceInput label="Überschrift" value={protocolHeadline} onChange={setProtocolHeadline} />
                <h3>Einladungen</h3>
                <EvjuSpaceInput label="Überschrift" value={invitatoionHeadline} onChange={setInvitationHeadline} />
                <EvjuSpaceEditor label="Text vor der Tagesordnung" rawState={invitatoionFirstText} onChangeExportRawState={(state: any) => {setInvitatoionFirstText(JSON.stringify(state))}} />
                <EvjuSpaceEditor label="Text nach der Tagesordnung" rawState={invitatoionSecondText} onChangeExportRawState={(state: any) => {setInvitatoionSecondText(JSON.stringify(state))}} />
            </Card>
        </>
    );
}
export default GroupSettings;

export async function groupSettingLoader({ params } : any) {

    const group = await getGroup(params.id)
    const server = await getServerFromGroup(params.id)

    console.log(group)

    return [ group, server ];
}